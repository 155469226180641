import React from 'react';
import Auth from './Auth';
import { Route, Switch, Redirect } from 'react-router-dom';
import Privacy from 'pages/Privacy';
import Terms from 'pages/Terms';
import Home from 'pages/Home';
import Restaurant from 'pages/Restaurant';

const Routing = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/auth" component={Auth} />
        <Route exact path="/legal/terms" component={Terms} />
        <Route exact path="/legal/privacy" component={Privacy} />
        <Route path="/:restaurantId" component={Restaurant} />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default Routing;
