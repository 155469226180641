import React, { useMemo, useState, useEffect, useCallback, useRef, createRef } from 'react';
import { addToCart } from 'redux/actions/cart';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatPrice } from 'utils/utils';
import { IconClose } from 'components/Icons';
import {
  InputGroupAddon,
  InputGroup,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import Loading from 'components/Loading';
import ModifierItem from 'components/ModifierItem';
import { updateModalVisibility } from 'redux/actions/ProductModal';
import MinusIcon from 'assets/icons/minus.svg';
import PlusIcon from 'assets/icons/plus.svg';
import { Box } from '@mui/material';

const initialItem = { count: 1, options: {}, instructions: '' };

const CardModal = ({ modal, closeModal, categoryId, productId, uuid }) => {
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;
  const [submitted, setSubmitted] = useState(false);
  const { categories, loading } = useSelector(({ Menu }) => Menu);
  const [tagTypeModifier, handleTagTypeModifier] = useState(null);
  const [selectedTagTypeModifier, handleSelectedTagTypeModifier] = useState(null);
  const { items } = useSelector(({ Cart }) => Cart);

  const dataModifier = items.length ? items.find((item) => item.product && item.uuid === uuid) : initialItem;
  const [errors, setErrors] = useState([]);
  const [item, setItem] = useState(dataModifier);
  const [elRefs, setElRefs] = useState([]);
  const modalRef = useRef();
  const modalBlock = useRef();
  const modalModifiersRef = useRef();
  const nameRef = useRef();
  const titlesNameRef = useRef();

  useEffect(() => {
    if (modalBlock.current || modal) {
      const modalFooterHeight = 75;
      const titleHeight = titlesNameRef?.current?.offsetHeight;
      const dialogHeight = modalRef?.current?._dialog?.offsetHeight;
      if (modalBlock.current) {
        modalBlock.current.style.height = dialogHeight - modalFooterHeight - titleHeight + 'px';
      }
    }
  }, [
    modalRef.current,
    modalBlock.current,
    closeModal,
    productId,
    categoryId,
    modal,
    uuid,
    titlesNameRef.current,
    window.location.href,
  ]);

  const product = useMemo(() => {
    let productData;
    items.forEach((productItem) => {
      if (productItem.uuid === uuid) {
        const productByCategory = categories.find((item) => item._id === categoryId);
        let product = productByCategory ? productByCategory.products.find((item) => item.id === productId) : {};
        if (productItem.product?.isOnePrice === false) {
          const sizeTypeModifier = product.modifiers.find((item) => item.tags?.includes('size'));
          const dependantModifiers = product.modifiers.filter((item) => item?.isOnePrice === false);
          if (!sizeTypeModifier) {
            productItem.product = null;
          }
          dependantModifiers.forEach((modifierData) => {
            modifierData.list.forEach((listItem) => {
              let dependentObject;
              listItem.priceOverride.forEach((priceOverrideItem) => {
                sizeTypeModifier.list.forEach((item) => {
                  if (item._id === priceOverrideItem.context_value) {
                    dependentObject = true;
                  }
                });
              });
              if (!dependentObject) {
                product.modifiers = product.modifiers.filter((item) => item._id !== modifierData._id);
              }
            });
          });
        } else {
          product.modifiers = product.modifiers.filter(
            (modifierData) => modifierData?.isOnePrice !== false && !modifierData.tags?.includes('size')
          );
        }

        productData = {
          ...productItem.product,
          modifiers: product.modifiers,
        };
      }
    });
    return productData || [];
  }, [items, uuid, categories, categoryId, productId]);

  const productFilteredModifiers = useMemo(() => {
    if (!product) return;
    const filteredModifierWithTags = product.modifiers.length
      ? product.modifiers.find((item) => item.tags?.includes('size'))
      : [];
    const filteredModifierWithoutTags = product.modifiers.filter((item) => !item.tags?.includes('size'));
    if (filteredModifierWithTags && product?.isOnePrice === false) {
      handleTagTypeModifier(filteredModifierWithTags);
    }
    return !product?.isOnePrice && filteredModifierWithTags
      ? [filteredModifierWithTags, ...filteredModifierWithoutTags]
      : filteredModifierWithoutTags;
  }, [product]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(product && productFilteredModifiers.length)
        .fill()
        .map((_, index) => elRefs[index] || createRef())
    );
  }, [dispatch, product, productFilteredModifiers.length]);

  const handleChangeCount = (count) => {
    setItem((prev) => ({ ...prev, count }));
  };

  const handleChangeInstructions = (instructions) => {
    setItem((prev) => ({ ...prev, instructions }));
  };

  const validateModifiers = useCallback(
    (newItem) => {
      if (!productFilteredModifiers?.length) return [[], true];
      const errors = [];
      productFilteredModifiers.forEach(({ min, max, _id }) => {
        const options = newItem.options && newItem.options[_id] ? newItem.options[_id] : [];
        const maxCount = max || 1000;
        if (options.length < min || options.length > maxCount) {
          errors.push(_id);
        }
      });
      setErrors(errors);
      return [errors, !errors.length];
    },
    [productFilteredModifiers]
  );

  const toggleOption = (opt) => {
    const { _id, price, name, priceOverride, tags, isOnePrice } = opt;
    if (tags?.length) {
      const copyObj = { ...item };
      const values = item.options ? Object.values(copyObj.options).flat() : [];
      values.forEach((optValue) => {
        if (!optValue.isOnePrice && selectedTagTypeModifier?.context_value !== _id) {
          const dependentObject = optValue.priceOverride.find(
            (overridePriceItem) => overridePriceItem.context_value === _id
          );
          optValue.price = dependentObject ? dependentObject.price : 0;
        }
      });
      setItem(item);
      if (selectedTagTypeModifier?._id === _id) {
        for (let prop in copyObj.options) {
          copyObj.options[prop] = copyObj.options[prop].filter((optValue) => optValue.isOnePrice);
        }
        handleSelectedTagTypeModifier(null);
      } else {
        handleSelectedTagTypeModifier({ name, price });
      }
    }

    if (!isOnePrice) {
      if (!selectedTagTypeModifier) {
        return;
      }
      opt.price = selectedTagTypeModifier
        ? priceOverride.find((item) => item.context_value === selectedTagTypeModifier?._id)?.price
        : 0;
    }

    const prevOptions = item.options ? item.options[opt.modifierId] || [] : [];
    let newOptions = [];
    if (prevOptions.find((item) => item?._id === opt._id)) {
      newOptions = prevOptions.filter(({ _id }) => _id !== opt._id);
    } else {
      newOptions = [...prevOptions, opt];
    }
    if (opt.freeCount) {
      newOptions = newOptions
        .sort((a, b) => b.originalPrice - a.originalPrice)
        .map((option, index) => ({ ...option, price: index < opt.freeCount ? 0 : option.originalPrice }));
    }

    const newItem = {
      ...item,
      options: {
        ...item.options,
        [opt.modifierId]: newOptions,
      },
    };
    if (submitted) {
      validateModifiers(newItem);
    }
    setItem(newItem);
  };

  const total = useMemo(() => {
    if (!product) return 0;
    let optPrice = 0;
    for (const key in item.options) {
      if (item.options.hasOwnProperty(key)) {
        const options = item.options[key];
        optPrice += options.reduce((acc, i) => acc + Number(i.freeCount ? i.price : i.originalPrice) || 0, 0);
      }
    }
    if (tagTypeModifier) {
      return (optPrice * item.count).toFixed(2);
    }
    return ((optPrice + product.price) * item.count).toFixed(2);
  }, [item.count, item.options, product, tagTypeModifier]);

  const executeScroll = (errors) => {
    for (let i = 0; i < productFilteredModifiers.length; i++) {
      if (errors.includes(productFilteredModifiers[i]._id)) {
        // For mobile view
        // if (errors.includes(productFilteredModifiers[0]._id)) {
        //   nameRef.current.scrollIntoView({ behavior: 'smooth' });
        // } else {
        // }
        elRefs[i].current.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  };

  const submitOrder = () => {
    setSubmitted(true);
    const [errors, isValid] = validateModifiers(item);
    if (isValid) {
      const data = {
        ...item,
        uuid: item.uuid || uuidv4(),
        // uuid: item.uuid,
        product: product._id,
      };
      dispatch(updateModalVisibility(false));
      dispatch(addToCart(data));
      closeModal(restaurant);
    } else {
      executeScroll(errors);
    }
  };

  const handleCloseModal = () => {
    closeModal(restaurant);
  };

  useEffect(() => {
    const { options } = item;
    const values = options ? Object.values(options).flat() : [];
    for (let i = 0; i < values.length; i++) {
      if (values[i].tags?.length) {
        handleSelectedTagTypeModifier(values[i]);
        break;
      }
    }
  }, [item]);

  if (!product) return <></>;
  return (
    <div className="container">
      <Modal
        ref={modalRef}
        className={`${product && product?.image?.url ? '' : 'cart--modal-single'} cart--modal card-modal-container`}
        isOpen={modal}
      >
        <ModalHeader>
          <Button aria-label="close" className="card--close-btn" color="secondary" onClick={handleCloseModal}>
            <IconClose />
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className="card-content">
            {product && product?.image?.url && (
              <div className="item-media">
                <img
                  src={`${product?.image?.url.replace(
                    process.env.REACT_APP_REPLACE,
                    process.env.REACT_APP_REPLACE_WITH
                  )}?tr=w-600,h-600`}
                  alt={`${restaurant} logo`}
                />
              </div>
            )}
            <div className="card--info-container" ref={modalModifiersRef}>
              <div className="card--product-info">
                {loading ? (
                  <Loading />
                ) : product ? (
                  <div ref={nameRef} className="card--content-wrapper">
                    <div ref={titlesNameRef} className="px-3 cart--product-desc" key={product._id}>
                      <h3 className="cart-product-title">{product.name}</h3>
                      <div className="text-muted">{product.description}</div>
                    </div>
                    <Box
                      ref={modalBlock}
                      sx={{
                        overflowY: 'auto',
                        // height: `${modalHeight}px`,
                      }}
                    >
                      {!!productFilteredModifiers.length && (
                        <div className="cart-body-content">
                          <div className="modifiers-list">
                            {productFilteredModifiers?.map((modifier, index) => (
                              <div
                                className="modifierWrapper"
                                key={modifier._id}
                                data-error={errors.includes(modifier._id)}
                                ref={elRefs[index]}
                              >
                                <ModifierItem
                                  data={modifier}
                                  tagTypeModifier={tagTypeModifier}
                                  selectedTagTypeModifier={selectedTagTypeModifier}
                                  onClick={toggleOption}
                                  values={item?.options && item.options[modifier._id]}
                                  hasError={errors.includes(modifier._id)}
                                  key={modifier._id}
                                  col={12}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <FormGroup className="mt-3 px-3 py-3 spacial">
                        <Label>Special instruction</Label>
                        <Input
                          rows="3"
                          name="instruction"
                          aria-label="instruction"
                          type="textarea"
                          value={item.instructions}
                          onChange={({ target }) => handleChangeInstructions(target.value)}
                        />
                      </FormGroup>
                    </Box>
                  </div>
                ) : (
                  <h2 className="m-auto text-center text-muted">Product not found!</h2>
                )}
                <div className="cart-foot">
                  <div className="card--multiply-btn">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <button
                          aria-label="minus"
                          className="btn btn-icon"
                          onClick={() => handleChangeCount(Math.max(item.count - 1, 1))}
                        >
                          <img src={MinusIcon} className="card-icon" alt="MINUS" />
                        </button>
                      </InputGroupAddon>
                      <Input value={item.count} readOnly min="1" />
                      <InputGroupAddon addonType="append">
                        <button
                          aria-label="plus"
                          className="btn btn-icon"
                          onClick={() => handleChangeCount(item.count + 1)}
                        >
                          <img src={PlusIcon} className="card-icon" alt="PLUS" />
                        </button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <div className="card--bottom">
                    <div className="cart--modal-footer">
                      <Button
                        aria-label="save"
                        className="add--basket-btn"
                        color="primary"
                        size="lg"
                        onClick={submitOrder}
                      >
                        SAVE <span>{selectedTagTypeModifier || !tagTypeModifier ? formatPrice(total) : ''}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

CardModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default CardModal;
