import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { formatPrice } from 'utils/utils';
import { MODAL_ERROR_MESSENGER } from 'redux/actions/types';

const ModalError = ({ errorMessages = '', settings = {}, className }) => {
  const [modal, setModal] = useState(true);
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch({ type: MODAL_ERROR_MESSENGER, payload: '' });
    setModal(!modal);
  };

  useEffect(() => {
    return () => dispatch({ type: MODAL_ERROR_MESSENGER, payload: '' });
  }, []);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className=" modal-error ">
        <ModalBody>{errorMessages ? <div className="text-error">{errorMessages}</div> : null}</ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button className="error-btn" onClick={toggle}>
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalError;
