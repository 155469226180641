import instance from 'services/api';
import { toast } from 'react-toastify';

export const getCart = ({ business }) => (dispatch) => {
  instance.defaults.headers['business'] = business;
  dispatch({ type: 'CART_LOADED', payload: { loading: true } });

  return instance
    .get('/cart')
    .then(({ data: { data } }) => {
      // There are cases we get products null
      const newData = data.items.filter((item) => item.product);
      dispatch({ type: 'CART_UPDATED', payload: { items: newData } });
    })
    .catch((e) => {
      dispatch({ type: 'CART_ERROR' });
    })
    .finally(() => dispatch({ type: 'CART_LOADED', payload: { loading: false } }));
};

export const addToCart = (body) => (dispatch) => {
  return instance.post(`/cart`, body).then(({ data: { data } }) => {
    toast.success('Product has been successfully added!');
    // There are cases we get products null
    const newData = data.filter((item) => item.product);
    return dispatch({ type: 'CART_UPDATED', payload: { items: newData } });
  });
};

export const getParentCart = (id) => (dispatch) => {
  return instance.get(`/cart/parent/${id}`).then(({ data: { data } }) => {
    if (Number(new Date(data.expirationDate)) > Date.now()) {
      localStorage.setItem('parentId', id);
      dispatch({ type: 'CART_UPDATED', payload: { parent: data } });
    }
  });
};

export const shareCart = (body) => (dispatch) => {
  return instance.post(`/cart/share`, body).then(({ data: { data } }) => {
    return data;
  });
};

export const shareComplete = (body) => {
  return instance.post(`/cart/share-complete`, body).then(({ data: { data } }) => {
    localStorage.removeItem('session_id');
    localStorage.removeItem('parentId');
    return data;
  });
};

export const updateQty = (id, params) => (dispatch, getState) => {
  const { Cart } = getState();
  instance.put(`/cart/${id}`, params).then(() => {
    const items = Cart.items.map((i) => (id === i.uuid ? { ...i, count: params.count } : i));
    dispatch({ type: 'CART_UPDATED', payload: { items } });
  });
};

export const deleteItem = (id) => (dispatch, getState) => {
  const { Cart } = getState();
  return instance.delete(`/cart/${id}`).then(() => {
    const items = Cart.items.filter(({ uuid }) => id !== uuid);
    dispatch({ type: 'CART_UPDATED', payload: { items } });
  });
};

export const deleteSharedItem = (id) => (dispatch, getState) => {
  const { Cart } = getState();
  return instance.delete(`/cart/share/${id}`).then(() => {
    const children = Cart.children.filter(({ _id }) => id !== _id);
    dispatch({ type: 'CART_UPDATED', payload: { children } });
  });
};

export const updateCartLoading = (payload) => (dispatch) => {
  return dispatch({ type: '', payload });
};
