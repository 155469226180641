import { MODAL_ERROR_MESSENGER } from 'redux/actions/types';
const initialState = {
  loading: false,
  children: [],
  items: [],
  total: 0,
  subtotal: 0,
  errorMessages: '',
};

const Cart = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'CART_LOADING':
      return { ...state, loading: payload };
    case 'CART_LOADED':
      return {
        ...state,
        ...payload,
      };
    case 'CART_UPDATED':
      return {
        ...state,
        ...payload,
      };
    case 'CART_ERROR':
      return { ...state, loading: false };
    case MODAL_ERROR_MESSENGER:
      return {
        ...state,
        errorMessages: payload,
      };
    case 'CART_EMPTY':
      return { loading: false, items: [], children: [], total: 0, subtotal: 0 };
    default:
      return state;
  }
};

export default Cart;
