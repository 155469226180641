import weekDays from 'constants/weekDays';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

const diffByHoursDuration = (start, end) => {
  const timeByMomentDate = moment(start, 'HH:mm:ss a');
  const endTime = moment(end, 'HH:mm:ss a');

  const startIsBefore = timeByMomentDate.isBefore(endTime) || timeByMomentDate.isSame(endTime);
  return startIsBefore;
};

export const workingHoursConverter = (data) => {
  const newHours = cloneDeep(data);
  Object.keys(weekDays).forEach((dayItem, indexDay) => {
    // const { hours } = newHours[dayItem];
    const hours = newHours[dayItem].hours.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
    const nextDay = Object.keys(weekDays)[indexDay + 1] || Object.keys(weekDays)[0];
    const nextDayCapitalize = nextDay.charAt(0).toUpperCase() + nextDay.slice(1);

    hours.forEach((hourItem, index) => {
      const startTime = hourItem.start;
      const endTime = hourItem.end;

      const durationByHours = diffByHoursDuration(startTime, endTime);
      const amPmStart = moment(startTime, 'HH:mm:ss').format('hh:mm a');
      const amPmEnd = moment(endTime, 'HH:mm:ss').format('hh:mm a');

      newHours[dayItem].timeApPm = newHours[dayItem].timeApPm
        ? `${newHours[dayItem].timeApPm}, ${amPmStart} - ${amPmEnd}`
        : `${amPmStart} - ${amPmEnd}`;
      if (!durationByHours) {
        newHours[dayItem].timeApPm = `${newHours[dayItem].timeApPm} (${nextDayCapitalize})`;
        if (
          newHours[dayItem].hours.length > 1
            ? newHours[dayItem].hours[1].end !== '00:00'
            : newHours[dayItem].hours[0].end !== '00:00'
        ) {
          newHours[dayItem].hours[index].end = '23:59';
          newHours[nextDay].hours.unshift({ start: '00:00', end: endTime });
        } else {
          newHours[dayItem].hours[index].end = '23:59';
        }
        if (!newHours[nextDay].opened) {
          newHours[nextDay].opened = true;
          newHours[nextDay].hours = [{ start: '00:00', end: endTime }];
        }
      }
    });
  });
  return newHours;
};

export const specialHoursConverter = (data, workingHours, workingHoursInit, timeZone) => {
  const newHours = cloneDeep(data);
  newHours.forEach((dayItem, indexDay) => {
    let { date, opened } = dayItem;
    const currentDay = moment(date).format('MM/DD/yyyy');
    const prevDayName = moment(currentDay).subtract(1, 'days').format('ddd').toLowerCase();
    let hours = dayItem.hours;

    workingHoursInit[prevDayName].hours.forEach((dayItem) => {
      const durationByHours = diffByHoursDuration(dayItem.start, dayItem.end);
      if (!durationByHours && dayItem.end !== '00:00') {
        hours.push({ start: '00:00', end: dayItem.end });
      }
    });

    if (!opened && hours.length > 1) {
      const indexOfInitRange = hours.findIndex((item) => {
        return item.start === '00:00' && item.end === '00:00';
      });
      hours.splice(indexOfInitRange, 1);
      newHours[indexDay].opened = true;
    }

    hours = hours.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
    const nextDay = moment(date).add(1, 'days').format('MM/DD/yyyy');
    const nextDayCapitalize = moment(nextDay).format('ddd');
    if (opened) {
      hours.forEach((hourItem, index) => {
        const startTime = hourItem.start;
        const endTime = hourItem.end;

        const durationByHours = diffByHoursDuration(startTime, endTime);
        const amPmStart = moment(startTime, 'HH:mm:ss').format('hh:mm a');
        const amPmEnd = moment(endTime, 'HH:mm:ss').format('hh:mm a');
        dayItem.timeApPm = dayItem.timeApPm
          ? `${dayItem.timeApPm}, ${amPmStart} - ${amPmEnd}`
          : `${amPmStart} - ${amPmEnd}`;

        if (!durationByHours) {
          // const date = [];
          const currentWeekDayItem = moment(nextDay).tz(timeZone).format('ddd').toLowerCase();
          // [{ start: '00:00', end: endTime }, ...workingHours[currentWeekDayItem]?.hours].forEach((item) => {
          //   date.push(moment(item.end, 'HH:mm:ss').format('hh:mm'));
          // });

          dayItem.timeApPm = `${dayItem.timeApPm} (${nextDayCapitalize})`;

          if (newHours[indexDay].hours[0].end !== '00:00') {
            newHours.push({
              date: nextDay,
              opened: true,
              hours: workingHours[currentWeekDayItem]?.opened
                ? [{ start: '00:00', end: endTime }, ...workingHours[currentWeekDayItem]?.hours]
                : [{ start: '00:00', end: endTime }],
              timeApPm: `${dayItem.timeApPm} (${nextDayCapitalize})`,
            });
          }
          hourItem.end = '23:59';
        }
      });
    }
  });
  return newHours;
};

export const isWithinInterval = (currentTime, start, end) => {
  const startSplit = start.split(':');
  const endSplit = end.split(':');
  const currentSplit = currentTime.split(':');
  const startMinutes = +startSplit[0] * 60 + +startSplit[1];
  let endMinutes = +endSplit[0] * 60 + +endSplit[1];
  const currentMinutes = +currentSplit[0] * 60 + +currentSplit[1];
  if (endMinutes < startMinutes) endMinutes += 24 * 60;
  if (startMinutes < currentMinutes && currentMinutes < endMinutes) {
    return true;
  }
  return false;
};
