import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import SideBar from 'components/SideMenu';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import webConfig from 'constants/webConfig';

const MenuNavigation = ({ categories }) => {
  const location = useLocation();
  const history = useHistory();
  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get('cid');

  const handleCategoryClick = (to) => {
    // const toCategoryId = `${webConfig.categoryIdPrefix}${to}`;
    // const toCategoryName = `${webConfig.categoryNamePrefix}${to}`;
    const toNavItemId = `${webConfig.navItemIdPrefix}${to}`;

    // const toCategoryNode = document.getElementById(toCategoryId);
    const toNavItemNode = document.getElementById(toNavItemId);

    // if (to !== `section-${id.toString()}`) {
    //   currentNavItem.classList.remove('active');
    //   toNavItem.classList.remove('left-item');
    // } else {
    //   currentNavItem.classList.add('active');
    //   toNavItem.classList.add('left-item');
    // }
    if (toNavItemNode && to !== categoryId) {
      // const menu = document.getElementById('menu');
      // menu.scroll({
      //   left: toNavItemNode.offsetLeft + Number.parseInt(toNavItemNode.offsetRight / 2) - webConfig.menuOffset,
      // });
      searchParams.set('cid', to);
      history.push(`/${restaurant}?${searchParams.toString()}`);
    }
  };

  const handleSetActive = (to) => {
    // const toCategoryId = `${webConfig.categoryIdPrefix}${to}`;
    // const toCategoryName = `${webConfig.categoryNamePrefix}${to}`;
    const toNavItemId = `${webConfig.navItemIdPrefix}${to}`;

    // const toCategoryNode = document.getElementById(toCategoryId);
    const toNavItemNode = document.getElementById(toNavItemId);

    if (toNavItemNode && to != categoryId) {
      toNavItemNode.classList.add('active');
      const menu = document.getElementById('menu');
      menu.scroll({
        left: toNavItemNode.offsetLeft - 100 - webConfig.menuOffset,
        behavior: 'smooth',
      });
      searchParams.set('cid', to);
      history.replace(`/${restaurant}?${searchParams.toString()}`);
    }
  };
  const handleSetInactive = (from) => {
    // const fromCategoryId = `${webConfig.categoryIdPrefix}${from}`;
    // const fromCategoryName = `${webConfig.categoryNamePrefix}${from}`;
    const fromNavItemId = `${webConfig.navItemIdPrefix}${from}`;

    // const fromCategoryNode = document.getElementById(fromCategoryId);
    const fromNavItemNode = document.getElementById(fromNavItemId);

    if (fromNavItemNode) {
      fromNavItemNode.classList.remove('active');
    }
  };

  useEffect(() => {
    const id = categoryId || categories[0]?._id;
    const currentNavItem = document.getElementById(`navItem-${id}`);
    const navItems = document.querySelectorAll('.navItem');
    const menu = document.getElementById('menu');
    if (currentNavItem) {
      currentNavItem.classList.add('active');
      menu.scroll({
        left: currentNavItem.offsetLeft - 100 - webConfig.menuOffset,
        behavior: 'smooth',
      });
    } else {
      if (currentNavItem) {
        currentNavItem.classList.remove('active');
      }
    }
    [].forEach.call(navItems, (el) => {
      if (!el.classList.value.includes('active')) {
        el.parentNode.classList.remove('active');
      }
    });
  }, [categories, categoryId]);

  return (
    <div className="banner-header">
      <div className="nav-sidemenu">
        <div className="sidemenu-bar">
          <SideBar handleCategoryClick={handleCategoryClick} categories={categories} />
        </div>
      </div>
      <div className="swiper-category d-flex align-items-center">
        <ul id="menu">
          {categories.map((category) => (
            <li
              className={category.disabled ? 'categoryDisabled' : ''}
              key={category._id}
              id={`navItem-${category._id}`}
            >
              <Link
                to={!category.disabled ? `${webConfig.categoryIdPrefix}${category._id.toString()}` : null}
                offset={webConfig.sectionOffset}
                activeClass="active"
                spy={true}
                absolute={true}
                className={`navItem ${category._id} ${category.disabled ? 'categoryDisabled' : ''}`}
                isDynamic={true}
                smooth={false}
                ignoreCancelEvents={false}
                onClick={() => (!category.disabled ? handleCategoryClick(category._id.toString()) : () => {})}
                onSetActive={() => (!category.disabled ? handleSetActive(category._id.toString()) : () => {})}
                onSetInactive={() => (!category.disabled ? handleSetInactive(category._id.toString()) : () => {})}
              >
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
// }

export default MenuNavigation;
