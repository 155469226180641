import React, { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import Routing from 'router/index';
import ScrollToTop from 'components/ScrollToTop/scrollToTop';
// import { useClearCacheCtx } from 'react-clear-cache';

const App = () => {
  // const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  // useEffect(() => {
  //   if (!isLatestVersion) {
  //     emptyCacheStorage();
  //   }
  // }, []);
  return (
    <main className="app-main">
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Routing />
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </main>
  );
};

export default App;
