import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import SwipeableDrawer from '@mui/material//SwipeableDrawer';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import List from '@mui/material//List';
import ListItem from '@mui/material//ListItem';
import { SideMenu, BannerClose } from 'components/Icons';
import webConfig from 'constants/webConfig';

const useStyles = makeStyles({
  list: {
    width: 360,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SideMenuBar({ categories, handleCategoryClick }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      id="sidebar-left"
    >
      <div className="d-flex justify-content-between px-3 py-4">
        <div className="side-title">Categories</div>
        <div className="">
          <BannerClose onClick={toggleDrawer(anchor, false)} />
        </div>
      </div>
      <div className="border-side" />
      <List>
        {categories.map(({ name, _id, disabled }) => (
          <ListItem disabled={disabled} key={uuidv4()} button>
            <Link
              activeClass="active"
              to={!disabled ? `${webConfig.categoryIdPrefix}${_id.toString()}` : null}
              spy={true}
              offset={webConfig.sectionOffset}
              isDynamic={true}
              smooth={true}
              // offset={isMobile ? -150 : -200}
              onClick={!disabled ? toggleDrawer(anchor, false) : null}
              onSetActive={(to) => (!disabled ? handleCategoryClick(to, _id) : null)}
              duration={1000}
            >
              {name}
            </Link>
          </ListItem>
        ))}
        <ListItem button className={``}></ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <div key={uuidv4()}>
          <div onClick={toggleDrawer(anchor, true)}>
            <SideMenu />
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </div>
      ))}
    </div>
  );
}
