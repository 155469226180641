import { MENU_LOADING, MENU_RES, MENU_ERROR, MENU_NOT_FOUND, MENU_UPDATE_WORKING_HOURS } from '../actions/types';

const initialState = {
  loading: true,
  notFound: true,
  activeItem: null,
  business: {},
  settings: {},
  categories: [],
  error: {},
};

const Menu = (state = initialState, { type, payload }) => {
  switch (type) {
    case MENU_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MENU_RES:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case MENU_UPDATE_WORKING_HOURS:
      return {
        ...state,
        loading: false,
        business: {
          ...state.business,
          workingHours: {
            ...payload,
          },
        },
      };
    case MENU_NOT_FOUND:
      return {
        ...state,
        notFound: payload,
      };

    case MENU_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default Menu;
