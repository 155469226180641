import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { updateFilterProduct } from 'redux/actions/search';
import { updateModalVisibility, updateProductModal } from 'redux/actions/ProductModal';
import Loading from 'components/Loading';
import MenuSection from 'components/MenuSection';
import ProductSearch from 'components/ProductSearch';
import ProductModal from 'components/ProductModal';
import { Element, scroller } from 'react-scroll';
import webConfig from 'constants/webConfig';
import { isMobile } from 'react-device-detect';
import { Box, Container, Alert, Typography } from '@mui/material';

import 'swiper/swiper.scss';
import MenuNavigation from './menuNavigation';

const Orders = () => {
  const [query, setQuery] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { items } = useSelector(({ Cart }) => Cart);

  const { categories, loading, closed, closedDates, settings } = useSelector(({ Menu }) => Menu);
  const { modalVisibility } = useSelector(({ ProductModal }) => ProductModal);
  const { hasResult } = useSelector(({ Search }) => Search);
  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;

  const searchParams = new URLSearchParams(location.search);

  const categoryId = useMemo(() => {
    return searchParams.get('cid');
  }, [searchParams]);

  const productId = useMemo(() => {
    return searchParams.get('pid');
  }, [searchParams]);

  const closeModal = (restaurant) => {
    dispatch(updateModalVisibility(false));
    dispatch(updateProductModal({}));
    searchParams.delete('pid');
    history.push(`/${restaurant}?${searchParams.toString()}`);
  };

  useEffect(() => {
    if (categoryId && productId) {
      dispatch(updateModalVisibility(true));
    } else {
      dispatch(updateModalVisibility(false));
    }
  }, [categoryId, dispatch, productId]);

  useEffect(() => {
    const currentElementByName = `name-${categoryId}`;
    scroller.scrollTo(currentElementByName, {
      offset: webConfig.sectionOffset,
    });
  }, []); // trigger only on page render

  useMemo(
    (data) => {
      const arrayOfQuery = query.toLowerCase().replace(/  +/g, ' ').split(' ');
      const filteredData = categories.map((data) => {
        return data.products.filter(({ name }) => {
          return arrayOfQuery.every((str) => {
            return name.toLowerCase().includes(str);
          });
        });
      });
      dispatch(updateFilterProduct(filteredData));
      return filteredData;
    },
    [categories, dispatch, query]
  );
  const handleSearch = (queryData) => {
    setQuery(queryData);
  };

  const categoriesProduct = categories.filter((item) => item.products.length);
  const pause = settings?.website?.storeStatus?.pause;

  return (
    <Box sx={{ px: 2 }}>
      {categories.length ? (
        <>
          <Container disableGutters>
            <div>
              <div className="menu-navbar search-navbar">
                <ProductSearch onChange={handleSearch} />
              </div>
              {pause ? (
                <Box>{<Alert severity="warning">We are not accepting orders right now.</Alert>}</Box>
              ) : !!closedDates ? (
                <Box>{!!closedDates && <Alert severity="warning">{closedDates}</Alert>}</Box>
              ) : !!closed ? (
                <Box>
                  <Alert severity="warning">{closed}</Alert>
                </Box>
              ) : (
                ''
              )}
              {/* <div className="px-md-3">{!!closed && <Alert color="warning">{closed}</Alert>}</div>
              <div className="px-md-3">{!!closedDates && <Alert color="warning">{closedDates}</Alert>}</div> */}
            </div>
            <MenuNavigation categories={categoriesProduct} />
            <Box id="containerElement" sx={{ pt: 4, '& > div': { mb: 2 } }} className="menu-sections">
              {!hasResult ? (
                categoriesProduct.map((item) => (
                  <Element
                    id={`section-${item.id.toString()}`}
                    // name={`name-${item.id.toString()}`}
                    key={'display' + item._id}
                  >
                    <MenuSection data={item} query={query} restaurant={restaurant} key={item.id} />
                  </Element>
                ))
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography className="not-found-product">No product matches your search criteria.</Typography>
                </Box>
              )}
            </Box>
            {modalVisibility && (
              <ProductModal
                modal={modalVisibility}
                closeModal={closeModal}
                categoryId={categoryId}
                productId={productId}
              />
            )}
            {isMobile && items.length ? (
              <Box id="checkoutButtonInOrder" sx={{ display: { md: 'none' } }} className="goCheckoutButtonWrapper">
                <button onClick={() => history.push(`/${restaurant}/cart`)} className="goCheckoutButton">
                  CHECKOUT
                  <Box component="span">{`(${items.length} ${items.length > 1 ? 'Items' : 'Item'})`}</Box>
                </button>
              </Box>
            ) : null}
          </Container>
        </>
      ) : loading ? (
        <Loading />
      ) : (
        <Typography sx={{ textAlign: 'center', p: 2 }}>Menu is empty!</Typography>
      )}
    </Box>
  );
};

export default Orders;
