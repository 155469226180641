import axios from 'axios';
//import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    common: {
      'app-v': '1.0.0',
    },
  },
});

export const setInstance = (token) => {
  localStorage.setItem('auth_token', token);
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
};

export const removeInstance = () => {
  localStorage.removeItem('auth_token');
  delete instance.defaults.headers['Authorization'];
};

if (localStorage.getItem('auth_token')) {
  instance.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('auth_token')}`;
}

export const setSessionId = (business) => {
  if (localStorage.getItem(business)) {
    instance.defaults.headers['SessionId'] = `${localStorage.getItem(business)}`;
  } else {
    const sessionid = v4();
    instance.defaults.headers['SessionId'] = `${sessionid}`;
    localStorage.setItem(business, sessionid);
  }
};

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data?.message) {
    }
    return Promise.reject(error);
  }
);

export default instance;
