import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useHistory } from 'react-router-dom';
import Check from 'assets/images/check.png';
import { getAddress } from 'helpers';

const enums = {
  pickup: 'Pick-up',
  curbsidePickup: 'Curbside Pick-up',
  delivery: 'Delivery',
};

const Restaurant = ({ data }) => {
  const history = useHistory();
  const { addressInfo } = data;
  const { addressPart1, addressPart2 } = getAddress(addressInfo);

  return (
    <Card
      sx={{ backgroundColor: 'white', mb: 3, width: 345, maxWidth: '100%', cursor: 'pointer', p: 2 }}
      onClick={() => history.push(`/${data?.settings?.url}`)}
    >
      {data.avatar?.url ? (
        <Box sx={{ borderRadius: '50%', overflow: 'hidden', width: '136px', height: '136px', mx: 'auto' }}>
          <CardMedia
            component="img"
            sx={{ maxWidth: '100%', maxHeight: '100%' }}
            image={`${data?.avatar?.url}?tr=w-136,h-136,cm-pad_resize,bg-F0F0F0`}
            // image={`${data?.avatar?.url}/tr:r-3000?tr=w-136,h-136,cm-pad_resize,bg-F0F0F0`}
            alt={data.companyName}
          />
        </Box>
      ) : (
        ''
      )}

      <CardContent sx={{ width: '100%', p: '0 !important' }}>
        <Typography sx={{ fontSize: '16px', mt: 2 }} className="restaurantTitle" variant="h5">
          {data.companyName}
        </Typography>
        {data.cuisineType ? (
          <Box sx={{ my: 1 }}>
            <Typography sx={{ fontSize: '16px', borderRadius: '4px', color: '#646464', mb: 1 }}>
              {data.cuisineType}
            </Typography>
          </Box>
        ) : (
          ''
        )}

        <Box sx={{ mt: 1, width: '100%' }}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: 1 }}>
              <img width="16" src={Check} alt="location icon" />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', width: 'calc(100% - 24px)' }}>
              <Typography
                className="fullAddress"
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ fontSize: 14 }}
              >
                {`${addressPart1}`}
              </Typography>

              <Typography
                className="fullAddress"
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ fontSize: 14 }}
              >
                {`${addressPart2}`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              fontSize: '14px',
              borderRadius: '4px',
              color: '#646464',
              mb: 1,
            }}
          >
            {`${data.distance} mi`}
            <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />
            {`${+data.defaultPrepTime - 10 < 5 ? 5 : +data.defaultPrepTime - 10} - ${+data.defaultPrepTime + 10} min`}
          </Typography>

          <Typography
            sx={{
              color: '#646464',
              fontSize: '14px',
              borderRadius: '4px',
            }}
          >
            {data.settings.pickup && enums.pickup}
            {data.settings.curbsidePickup && (
              <>
                {data.settings.pickup && <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />}
                {enums.curbsidePickup}
              </>
            )}
            {data.settings.delivery && (
              <>
                {(data.settings.pickup || data.settings.curbsidePickup) && (
                  <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />
                )}

                {enums.delivery}
              </>
            )}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Restaurant;
