import React from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { ReactComponent as IconPhone } from 'assets/icons/phone.svg';
import { ReactComponent as IconMapPin } from 'assets/icons/map-pin.svg';
import { ReactComponent as IconBriefcase } from 'assets/icons/briefcase.svg';

export const DAYS = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};
const Contacts = () => {
  const { business } = useSelector(({ Menu }) => Menu);

  return (
    <div className="contacts-page">
      <Container className="py-4">
        <Row>
          <Col md="6">
            <h4 className="mb-3">{business.companyName}</h4>
            <address className="contact-info mb-2">
              <IconMapPin />
              {business.address}
            </address>
            <div className="contact-info mb-2">
              <IconPhone />
              <a href={`tel:${business.phone}`} className="text-body">
                {business.phone}
              </a>
            </div>
            <div className="contact-info mb-2">
              <IconBriefcase />
              {business.about}
            </div>
            <div className="contact-info mb-2">
              <Table borderless size="sm">
                <tbody>
                  {Object.keys(business.workingHours).map((key) => (
                    <tr key={key}>
                      <td className="pl-0">{DAYS[key]}</td>
                      <td className="pr-0 text-right text-nowrap">
                        {business.workingHours[key].opened
                          ? business.workingHours[key].hours.map((i) => `${i.start} - ${i.end}`).join(', ')
                          : 'Closed'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md="6">
            <div className="iframe-wrapper shadow-sm">
              <iframe
                title="map"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAP_KEY}&q=${encodeURI(
                  business.address
                )}`}
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contacts;
