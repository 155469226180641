import React from 'react';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';

const ConfirmModal = ({
  size,
  title,
  onClose,
  message,
  className,
  cancelText,
  confirmText,
  cancelColor,
  confirmColor,
  buttonsComponent,
}) => {
  let buttonsContent = (
    <div className="d-flex justify-content-end w-100">
      {cancelText && (
        <Button className="mr-2" color={cancelColor} onClick={() => onClose(false)}>
          {cancelText}
        </Button>
      )}{' '}
      <Button color={confirmColor} onClick={() => onClose(true)}>
        {confirmText}
      </Button>
    </div>
  );

  if (buttonsComponent) {
    const CustomComponent = buttonsComponent;
    buttonsContent = <CustomComponent onClose={onClose} />;
  }

  return (
    <Modal
      isOpen
      centered
      size={size}
      fade={false}
      toggle={() => onClose(false)}
      className={`confirm-modal   ${className}`}
    >
      <ModalBody>
        <button aria-label="close" className="close" onClick={() => onClose(false)}>
          <i className="icon icon-x-circle" />
        </button>
        {!!title && <h3>{title}</h3>}
        {!!message && <div className="text-muted mt-3">{message}</div>}
      </ModalBody>
      <ModalFooter>{buttonsContent}</ModalFooter>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Are you sure?',
  confirmText: 'Ok',
  cancelText: 'Cancel',
  confirmColor: 'primary',
  cancelColor: 'danger',
  className: '',
  buttonsComponent: null,
  size: null,
};

export default ConfirmModal;
