import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AvForm, AvField, AvRadioGroup, AvRadio, AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { formatPrice } from 'utils/utils';
import { toast } from 'react-toastify';
import instance from 'services/api';
import PlaceInputField from '../PlaceInputField';
import DateInputField from '../DateInputField';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPayment } from 'redux/actions/order';
import stripeConf from 'constants/stripeConf';

import Checkbox from '@mui/material/Checkbox';

import moment from 'moment';
import { Button, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent, Label, Input, FormGroup } from 'reactstrap';
import { LoadingSpinner } from 'components/Icons/index';
import Coupon from 'assets/icons/coupon.png';
import ModalError from 'components/ModalError/index';
import { withDebounce } from 'helpers/index';
import { MODAL_ERROR_MESSENGER } from 'redux/actions/types';
import { Box, Divider, Alert, FormControlLabel, Stack, Typography } from '@mui/material';
import { validateCoupone } from 'redux/actions/order';
let prevAddress = {};
let prevChecked = '';
let timeout;

const METHOD_CASH = 'cash';
const METHOD_CARD = 'card';
const METHOD_STORE = 'store';
const addressKye = 'addr877727266';
const getModel = () => (localStorage.getItem('cartValues') ? JSON.parse(localStorage.getItem('cartValues')) : {});
const couponCodeInitialValues = { value: '', loading: false, submitted: false };

const CheckoutForm = ({ restaurant, total, onSubmit, settings, closed, closedDates, asap, loading }) => {
  const dispatch = useDispatch();
  const [model, setModel] = useState({
    applied: false,
    name: '',
    phone: '',
    email: '',
    beReadyTime: '',
    deliveryInstructions: '',
    // tipInfo: { driver: 0, kitchen: 3 },
  });
  const elements = useElements();
  const stripe = useStripe();
  const history = useHistory();
  const formRef = useRef(null);
  const [tip, setTip] = useState(3);
  const [cashback, setCashback] = useState(0);
  const [couponCode, setCouponCode] = useState(couponCodeInitialValues);
  const [acceptUpdatesPromotionEmails, setAcceptUpdatesPromotionEmails] = useState(false);
  const [applied, setApplied] = useState(Boolean(model.applied));
  const [customTip, setCustomTip] = useState('');
  const [addressInfo, setAddressInfo] = useState({ checked: false, valid: false, data: null });
  const [fullAddress, handleFullAddress] = useState({});
  const [orderType, setOrderType] = useState('pickup');
  const [deliveryTime, setDeliveryTime] = useState(!!closed || asap?.disableAsap ? 'date' : 'asap');
  const [paymentMethod, setPaymentMethod] = useState(METHOD_CASH);
  const { errorMessages } = useSelector(({ Cart }) => Cart);
  const { business, companyName } = useSelector(({ Menu }) => Menu);
  const [cardError, setCardError] = useState(null);
  const [modal, setModal] = useState(true);
  const [calendarError, handleCalendarError] = useState('');
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState({
    expireTime: '',
    fee: 0,
    jobId: '',
    quoteId: '',
    taskId: '',
  });
  const isNashDelivery = settings.deliveryBy.every((item) => item.deliveryService === 'nash');
  const toggle = () => setModal(!modal);
  const isDelivery = orderType === 'delivery';
  const isCash = paymentMethod === METHOD_CASH;
  const isCard = paymentMethod === METHOD_CARD;

  const tipVal = isCash ? 0 : +customTip || tip;
  const taxTotal = Math.round(total.taxTotal) / 100;
  const subTotal = total.subTotal;
  const tipByDeliveryMsg = orderType === 'delivery' ? '(all tips go to delivery driver)' : null;
  // const [dateErrorMessage, handleDateErrorMessage] = useState('');

  const couponeAmount = useMemo(() => {
    if (couponCode._id) {
      if (couponCode.amount) {
        return couponCode.amount;
      }
      if (couponCode.percent) {
        return (subTotal * couponCode.percent) / 100;
      }
    } else {
      return null;
    }
  }, [couponCode._id, couponCode.amount, couponCode.percent, subTotal]);

  let deliveryFee = isDelivery
    ? addressInfo.data && addressInfo.valid && (!settings.deliveryFee || settings.deliveryFee == 0)
      ? addressInfo.data.fee / 100
      : settings.deliveryFee
    : 0;
  if (couponCode?.freeDelivery) {
    deliveryFee = 0;
  }
  let grandTotal = subTotal + taxTotal + tipVal + deliveryFee;
  const discount = cashback && applied ? Math.min(grandTotal, cashback) : 0;
  if (couponeAmount) {
    if (grandTotal - couponeAmount < 0) {
      grandTotal = 0;
    } else {
      grandTotal -= couponeAmount;
    }
  }
  if (cashback && applied) {
    grandTotal -= discount;
  }

  moment.tz.setDefault(business.timezone.timeZoneId);

  const getCashback = useCallback(
    (value) => {
      instance.post(`/purchase/phone`, { phone: value, business: settings.id }).then(({ data: { data } }) => {
        prevChecked = value;
        if (data?.cashback) {
          setCashback(data.cashback);
        } else {
          prevChecked = '';
          setCashback(0);
        }
      });
    },
    [settings.id]
  );

  const checkCashback = ({ target: { value, name, classList } }) => {
    setModel({ ...model, [name]: value });
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value) {
        if (classList.contains('av-valid') && prevChecked !== value) {
          getCashback(value);
        }
      } else {
        prevChecked = '';
        setCashback(0);
        setApplied(false);
      }
    }, 800);
  };

  useEffect(() => {
    if (model.phone) {
      getCashback(model.phone);
    }
  }, [getCashback, model.phone]);

  useEffect(() => {
    // const prevent = (event) => {
    //   if (event.keyCode === 13) {
    //     event.preventDefault();
    //     return false;
    //   }
    // };
    prevChecked = '';
    // window.addEventListener('keydown', prevent);
    if (!settings.pickup) {
      setOrderType('curbsidePickup');
    }
    if (!settings.pickup && !settings.curbsidePickup && settings.delivery) {
      setOrderType('delivery');
    }
    if (!settings.payInStore) {
      setPaymentMethod('card');
    }
    // return () => {
    //   window.removeEventListener('keydown', prevent);
    // };
  }, [settings, settings.curbsidePickup, settings.delivery, settings.payInStore, settings.pickup]);

  const handleSucceed = (data) => {
    dispatch({ type: 'CART_EMPTY' });
    history.push({ pathname: `/${restaurant}/success`, state: { orderNumber: data.number } });
    localStorage.removeItem('cartValues');
  };

  const handleSubmit = (e, values) => {
    setCardError(null);
    dispatch({ type: 'CART_LOADING', payload: true });
    const isScheduled = deliveryTime !== 'asap';
    const year = new Date(model.beReadyTime).getFullYear();
    const day = new Date(model.beReadyTime).getDate();
    const hour = new Date(model.beReadyTime).getHours();
    const minute = new Date(model.beReadyTime).getMinutes();
    const month = moment(model.beReadyTime).get('M');
    const secund = new Date(model.beReadyTime).getSeconds();
    const beReadyTime = !isScheduled
      ? moment.tz(business.timezone.timeZoneId).add(settings.defaultPrepTime, 'minutes').format()
      : moment().set({ year: year, month: month, date: day, hour: hour, minute: minute, second: secund }).format();
    const formData = {
      ...values,
      discount,
      couponId: couponCode._id,
      orderType,
      isScheduled,
      beReadyTime,
      tip: tipVal,
      tipInfo: { ...model.tipInfo, kitchen: tipVal },
      paymentMethod,
      useDiscount: applied,
      companyName: companyName,
      total: Number(grandTotal),
      acceptUpdatesPromotionEmails,
      delivery: values.delivery
        ? {
            ...values.delivery,
            address1: values.delivery[addressKye],
            address2: model?.addressInfo?.address2,
            zipCode: model?.addressInfo?.zipCode,
            state: model?.addressInfo?.state,
            city: model?.addressInfo?.city,
            fullAddress: model?.addressInfo?.fullAddress,
          }
        : {},

      addressInfo: { ...model.addressInfo }, //address2
      selectedTask,
    };
    if (isScheduled && calendarError) {
      dispatch({ type: 'CART_LOADING', payload: false });
      return;
    }

    if (isCash) {
      return onSubmit(formData)
        .then(handleSucceed)
        .finally(() => dispatch({ type: 'CART_LOADING', payload: false }));
    }
    if (!stripe || !elements) {
      dispatch({ type: 'CART_LOADING', payload: false });
      return dispatch({ type: MODAL_ERROR_MESSENGER, payload: "Stripe.js hasn't loaded yet." });
      // return toast.error("Stripe.js hasn't loaded yet.");
    }

    const cardElement = elements.getElement(CardNumberElement);
    return onSubmit(formData)
      .then(({ payment, order }) => {
        if (payment === 0 || payment) {
          if (payment === 0) {
            dispatch(confirmPayment({ id: order._id, phone: formData.phone, acceptUpdatesPromotionEmails }))
              .then(handleSucceed)
              .finally(() => dispatch({ type: 'CART_LOADING', payload: false }));
          } else {
            stripe
              .confirmCardPayment(payment.client_secret, {
                payment_method: { card: cardElement },
              })
              .then(({ error, paymentMethod }) => {
                if (error) {
                  dispatch({ type: MODAL_ERROR_MESSENGER, payload: error.message });
                  // toast.error(error.message);
                  setCardError([
                    {
                      error: error.message,
                    },
                  ]);
                } else {
                  setCardError(null);
                  dispatch(confirmPayment({ id: order._id, phone: formData.phone, acceptUpdatesPromotionEmails }))
                    .then(handleSucceed)
                    .finally(() => dispatch({ type: 'CART_LOADING', payload: false }));
                }
              })
              .catch(() => {
                dispatch({ type: 'CART_LOADING', payload: false });
              });
          }
        } else {
          dispatch({ type: 'CART_LOADING', payload: false });
          setCardError([
            {
              error: {
                card: 'The credit card is invalid.',
              },
            },
          ]);
          dispatch({ type: MODAL_ERROR_MESSENGER, payload: 'The credit card is invalid.' });
          // toast.error('The credit card is invalid.');
        }
      })
      .catch(() => {
        dispatch({ type: 'CART_LOADING', payload: false });
      });
  };

  const validateNashData = (data) => {
    if (!model.phone || !model.name) {
      return setAddressInfo({
        checked: true,
        valid: false,
        data: false,
        message: data?.message,
      });
    }
  };

  const handleFocus = () => {
    const isScheduled = deliveryTime !== 'asap';
    const errorMsg =
      'Your name and phone number are missing. Please complete them and type the delivery address again.';
    if (isNashDelivery && (!model.phone || !model.name)) {
      return setAddressInfo({
        checked: true,
        valid: false,
        data: false,
        message: errorMsg,
      });
    }

    if (isScheduled && !model.beReadyTime) {
      return setAddressInfo({
        checked: true,
        valid: false,
        data: false,
        message: 'Date/Time are missing. Please complete them and type the delivery address again.',
      });
    }
  };

  const validateAddress = (value) => {
    const isScheduled = deliveryTime !== 'asap';
    if (isNashDelivery && (!model.phone || !model.name)) {
      return;
    }
    if (isScheduled && !model.beReadyTime) {
      return;
    }

    const year = new Date(model.beReadyTime).getFullYear();
    const day = new Date(model.beReadyTime).getDate();
    const hour = new Date(model.beReadyTime).getHours();
    const minute = new Date(model.beReadyTime).getMinutes();
    const month = moment(model.beReadyTime).get('M');
    const secund = new Date(model.beReadyTime).getSeconds();
    const beReadyTime = !isScheduled
      ? moment.tz(business.timezone.timeZoneId).add(settings.defaultPrepTime, 'minutes').format()
      : moment().set({ year: year, month: month, date: day, hour: hour, minute: minute, second: secund }).format();

    if (!value) {
      setAddressInfo({ checked: false, valid: false, data: null, message: '' });
    }
    // handleFullAddress(value);
    if (value && prevAddress.value !== value) {
      setAddressLoading(true);

      setModel({
        ...model,
        addressInfo: value,
      });
      instance
        .post(`/purchase/address`, {
          addressInfo: value,
          address: value.fullAddress || value,
          business: settings.id,
          customer: {
            phoneNumber: model.phone,
            name: model.name,
          },
          dropoffInstructions: model.deliveryInstructions,
          packageDeliveryMode: 'scheduled',
          packageTax: taxTotal,
          subTotal: total.subTotal,
          beReadyTime: beReadyTime,
          tipAmountUsd: tipVal || 0,
        })
        .then(({ data: { data, errors } }) => {
          prevAddress = { value, data };
          setSelectedTask({
            ...data.selectedTask,
          });
          return setAddressInfo({ checked: true, valid: !!data?.isOk, data: data, message: data?.message });
        })
        .catch((err) => {
          if (isNashDelivery) {
            return validateNashData(err?.response?.data || err?.response);
          }
          return setAddressInfo({ checked: true, valid: false, data: false, message: err?.response?.data?.message });
        })
        .finally(() => {
          setAddressLoading(false);
        });
    }
  };

  const handleChangeTip = (val) => {
    setTip(val);
    setCustomTip('');
  };

  const handleChangeCustomTip = (val) => {
    setTip(0);
    setCustomTip(val);
  };

  const handleChangeMethod = (method) => {
    setPaymentMethod(method);
  };
  const handleChange = (e) => {
    setAcceptUpdatesPromotionEmails(e.target.checked);
  };
  const handelChangeType = (type) => {
    setOrderType(type);
    if (type === 'delivery' && paymentMethod === METHOD_CASH) {
      setPaymentMethod(settings.payment ? METHOD_CARD : null);
    } else if (!paymentMethod) {
      setPaymentMethod(METHOD_CASH);
    }
  };

  const handleError = (event, errors) => {
    const input = document.querySelector(`[name="${errors[0]}"]`);
    window.scrollTo(0, input.offsetParent?.offsetTop - 115);
    if (errors.includes('beReadyTime')) {
      handleCalendarError('This field is required');
    } else {
      handleCalendarError('');
    }
  };

  const handleValidateCouponCode = useCallback(() => {
    const formData = formRef.current;
    setCouponCode({ ...couponCode, loading: true });
    dispatch(
      validateCoupone({
        couponCode: couponCode.value,
        customerPhone: formData.getValues().phone,
      })
    )
      .then((data) => {
        setCouponCode(data);
      })
      .catch(() => {
        hanldeResetCouponCode();
      })
      .finally(() => {
        // setCouponCode({ ...couponCode, loading: false });
      });
  }, [couponCode.value, dispatch]);

  const hanldeResetCouponCode = () => {
    setCouponCode(couponCodeInitialValues);
  };

  const handleChangeDrivier = (e, value) => {
    if (e.target.checked) {
      setModel({ ...model, tipInfo: { ...model.tipInfo, driver: 2 } });
    } else {
      setModel({ ...model, tipInfo: { ...model.tipInfo, driver: 0 } });
    }
  };

  useEffect(() => {
    const formData = formRef.current;
    return () => {
      const values = { ...formData?.getValues() };
      localStorage.setItem(
        'cartValues',
        JSON.stringify({
          name: values.name,
          phone: values.phone,
          // mail:values.mail,
          applied,
        })
      );
    };
  }, [applied]);

  const handleAddressChange = (e) => {
    const { name, value } = e.currentTarget;
    const copyModel = { ...model };
    if (name === 'address2') {
      copyModel.delivery ? (copyModel.delivery.address2 = value) : (copyModel.delivery = { address2: value });
    }
    const newAddressInfo = { ...copyModel.addressInfo, [name]: value };
    newAddressInfo.fullAddress = `${newAddressInfo.address1} ${newAddressInfo.address2}, ${newAddressInfo.city}, ${newAddressInfo.state} ${newAddressInfo.zipCode}, ${newAddressInfo.country}`;
    setModel({ ...copyModel, addressInfo: newAddressInfo });
  };

  const isOverMin = useMemo(
    () =>
      settings.minOrder ? (applied ? grandTotal + couponeAmount + discount : grandTotal) >= settings.minOrder : true,
    [settings.minOrder, applied, grandTotal, discount]
  );

  // const isOverMin = useMemo(
  //   () => (settings.minOrder ? (applied ? grandTotal + discount : grandTotal) >= settings.minOrder : true),
  //   [settings.minOrder, applied, grandTotal, discount]
  // );

  const disabledForm = !settings.pickup && !settings.curbsidePickup && !settings.delivery;
  const disabledPayments = !settings.payInStore && !settings.creditCard;
  const pause = settings.settings?.website?.storeStatus?.pause;
  if (pause) {
    return <Alert severity="warning">We are not accepting orders right now.</Alert>;
  }
  return (
    <>
      {disabledForm || disabledPayments ? (
        <></>
      ) : (
        <AvForm
          ref={formRef}
          autoComplete="off"
          onValidSubmit={withDebounce(handleSubmit)}
          onInvalidSubmit={handleError}
          model={model}
        >
          <div className="cart-body-content mx-0">
            {/* Working hours close */}
            {!!closed ? (
              <div className="px-md-3">{!!closed && <Alert severity="warning">{closed}</Alert>}</div>
            ) : !!closedDates ? (
              <div className="px-md-3">{!!closedDates && <Alert severity="warning">{closedDates}</Alert>}</div>
            ) : (
              ''
            )}
            {/* <div className="px-md-3">{!!closed && <Alert color="warning">{closed}</Alert>}</div>
            {/* Work Holiday & Special Hours  */}
            {/* <div className="px-md-3">{!!closedDates && <Alert color="warning">{closedDates}</Alert>}</div> */}
            {/*  Working hours close && Work Holiday & Special Hours */}
            <div className="checkout-form pt-4 pt-md-0">
              <div className="px-3 pt-3">
                <h2 className="checkout-form-title font-weight-bold">Checkout</h2>
              </div>
              <div className="px-3 mb-5">
                <Row form>
                  <div className="col-md-12">
                    <AvField
                      label="Name *"
                      name="name"
                      role="presentation"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required' },
                      }}
                      value={model.name}
                      onChange={(e) => setModel({ ...model, name: e.target.value })}
                    />
                  </div>
                  <div className="col-md-12">
                    <AvField
                      name={'phone'}
                      role="presentation"
                      label="Phone Number *"
                      onChange={checkCashback}
                      // type="tel"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required' },
                      }}
                    />
                    {cashback ? (
                      applied ? (
                        <div className="d-flex align-items-center">
                          <b className="mr-2">{formatPrice(discount)}</b> cashback applied{' '}
                          <Button color="link" className="text-danger" type="button" onClick={() => setApplied(false)}>
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-start justify-content-md-between px-2">
                          <span className="cashback-text mr-3 mr-md-0">
                            Your Cashback: <b className="ml-2">{formatPrice(cashback)}</b>
                          </span>
                          <Button className="apply-btn" color="link" type="button" onClick={() => setApplied(true)}>
                            Apply
                          </Button>
                        </div>
                      )
                    ) : null}
                    <FormControlLabel
                      control={<Checkbox color="warning" />}
                      label="Text me with news and offers"
                      checked={acceptUpdatesPromotionEmails}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <AvField
                      role="presentation"
                      label="Email"
                      name="email"
                      type="email"
                      value={model.email}
                      onChange={(e) => setModel({ ...model, email: e.target.value })}
                    />
                  </div>
                </Row>
              </div>

              {/*  Pickup && Curbside Pickup &&  Delivery */}
              <div className="form-section mt-5 mt-md-0 pickup-section">
                <Nav tabs>
                  {settings.pickup && (
                    <NavItem>
                      <NavLink
                        className={orderType === 'pickup' ? 'active' : ''}
                        onClick={() => handelChangeType('pickup')}
                      >
                        Pickup
                      </NavLink>
                    </NavItem>
                  )}
                  {/* Curbside Pickup  */}
                  {settings.curbsidePickup && (
                    <NavItem>
                      <NavLink
                        className={orderType === 'curbsidePickup' ? 'active' : ''}
                        onClick={() => handelChangeType('curbsidePickup')}
                      >
                        Curbside pickup
                      </NavLink>
                    </NavItem>
                  )}
                  {/* Delivery */}
                  {settings.delivery && (
                    <NavItem>
                      <NavLink
                        className={orderType === 'delivery' ? 'active' : ''}
                        onClick={() => handelChangeType('delivery')}
                      >
                        Delivery
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={orderType}>
                  {/* Pickup tab */}
                  <TabPane tabId="pickup">
                    {/* Order ready in {settings.defaultPrepTime} min. */}
                    {orderType === 'pickup' && (
                      <div className="deliveryGroup">
                        <AvRadioGroup
                          name="deliveryTime"
                          label={`Pickup time`}
                          value={deliveryTime}
                          required
                          className="delivery-radio"
                        >
                          {asap?.disableAsap ? (
                            <></>
                          ) : (
                            <AvRadio
                              customInput
                              label="ASAP"
                              value="asap"
                              onChange={(e) => setDeliveryTime(e.target.value)}
                              disabled={!!closed}
                            />
                          )}
                          <AvRadio
                            customInput
                            checked={true}
                            label="Date/Time"
                            value="date"
                            onChange={(e) => setDeliveryTime(e.target.value)}
                          />
                        </AvRadioGroup>
                      </div>
                    )}

                    {deliveryTime === 'date' && (
                      <div className="group-date p-xl-0">
                        <DateInputField
                          calendarError={calendarError}
                          handleCalendarError={handleCalendarError}
                          defaultPrepTime={business.defaultPrepTime}
                          timeZoneId={business.timezone.timeZoneId}
                          name="beReadyTime"
                          required={deliveryTime === 'date'}
                          specialDates={settings.specialDates}
                          openingHours={settings.workingHours}
                          startMinDate={settings.startMinDate}
                          pickupTimeData={asap}
                          setModel={setModel}
                          modelData={model}
                        />
                        <div className="text-muted px-1">{`All times in ${settings?.timezone?.timeZoneName}`}</div>
                      </div>
                    )}
                  </TabPane>

                  {/* Curbside pickup tab */}
                  <TabPane tabId="curbsidePickup">
                    {/* Order ready in {settings.defaultPrepTime} min. */}
                    {orderType === 'curbsidePickup' && (
                      <div className="deliveryGroup">
                        <AvRadioGroup
                          name="deliveryTime"
                          label={`Pickup time`}
                          value={deliveryTime}
                          required
                          className="delivery-radio"
                        >
                          {asap?.disableAsap ? (
                            <></>
                          ) : (
                            <AvRadio
                              customInput
                              label="ASAP"
                              value="asap"
                              onChange={(e) => setDeliveryTime(e.target.value)}
                              disabled={!!closed}
                            />
                          )}
                          <AvRadio
                            customInput
                            label="Date/Time"
                            value="date"
                            onChange={(e) => setDeliveryTime(e.target.value)}
                          />
                        </AvRadioGroup>
                      </div>
                    )}
                    {/* )} */}
                    {deliveryTime === 'date' && (
                      <div className="group-date p-xl-0">
                        <DateInputField
                          role="presentation"
                          calendarError={calendarError}
                          handleCalendarError={handleCalendarError}
                          defaultPrepTime={business.defaultPrepTime}
                          timeZoneId={business.timezone.timeZoneId}
                          name="beReadyTime"
                          required={deliveryTime === 'date'}
                          specialDates={settings.specialDates}
                          startMinDate={settings.startMinDate}
                          openingHours={settings.workingHours}
                          pickupTimeData={asap}
                          setModel={setModel}
                          modelData={model}
                        />
                        <div className="text-muted px-1">{`All times in ${settings?.timezone?.timeZoneName}`}</div>
                      </div>
                    )}
                  </TabPane>

                  {/* Delivery Tab */}
                  <TabPane tabId="delivery">
                    {orderType === 'delivery' && (
                      <div className="deliveryGroup">
                        <AvRadioGroup
                          name="deliveryTime"
                          label={`${isDelivery ? 'Delivery' : 'Pickup'} time`}
                          value={deliveryTime}
                          required
                          className="delivery-radio"
                        >
                          {asap?.disableAsap ? (
                            <></>
                          ) : (
                            <AvRadio
                              customInput
                              label="ASAP"
                              value="asap"
                              onChange={(e) => setDeliveryTime(e.target.value)}
                              disabled={!!closed}
                            />
                          )}
                          <AvRadio
                            customInput
                            label="Date/Time"
                            value="date"
                            onChange={(e) => setDeliveryTime(e.target.value)}
                          />
                        </AvRadioGroup>
                      </div>
                    )}
                    {deliveryTime === 'date' && (
                      <div className="group-date p-xl-0">
                        <DateInputField
                          calendarError={calendarError}
                          handleCalendarError={handleCalendarError}
                          defaultPrepTime={business.defaultPrepTime}
                          timeZoneId={business.timezone.timeZoneId}
                          name="beReadyTime"
                          required={deliveryTime === 'date'}
                          specialDates={settings.specialDates}
                          startMinDate={settings.startMinDate}
                          openingHours={settings.workingHours}
                          pickupTimeData={asap}
                          setModel={setModel}
                          modelData={model}
                        />
                        <div className="text-muted px-1">{`All times in ${settings?.timezone?.timeZoneName}`}</div>
                      </div>
                    )}
                    <Row className="mt-5" form>
                      <Col md={12} className="placeInputField">
                        <PlaceInputField
                          role="presentation"
                          label="Address*"
                          placeholder="Start typing an address …"
                          required={isDelivery}
                          onBlur={validateAddress}
                          onFocus={handleFocus}
                          validateAddress={validateAddress}
                          name={`delivery.${addressKye}`}
                          validate={{
                            required: { value: orderType === 'delivery', errorMessage: 'This field is required' },
                          }}
                        />
                        {addressLoading ? (
                          <LoadingSpinner height="50px" />
                        ) : (
                          !addressLoading &&
                          addressInfo.checked &&
                          !addressInfo.valid && (
                            <Alert severity="error">
                              {addressInfo.message || "Can't be delivered to this address!"}
                            </Alert>
                          )
                        )}
                      </Col>
                      <Col md={12}>
                        <AvField
                          role="presentation"
                          onBlur={() => validateAddress(model.addressInfo)}
                          value={model.addressInfo?.address2}
                          label="Apt/Unit Number"
                          onChange={handleAddressChange}
                          name="address2"
                          autoComplete="off"
                        />
                      </Col>
                      <Col md={4}>
                        <AvField
                          role="presentation"
                          onBlur={() => validateAddress(model.addressInfo)}
                          value={model.addressInfo?.city}
                          label="City"
                          name="city"
                          onChange={handleAddressChange}
                          autoComplete="off"
                          validate={{
                            required: { value: orderType === 'delivery', errorMessage: 'This field is required' },
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <AvField
                          role="presentation"
                          onChange={handleAddressChange}
                          onBlur={() => validateAddress(model.addressInfo)}
                          value={model.addressInfo?.state}
                          label="State"
                          name="state"
                          autoComplete="off"
                          validate={{
                            required: { value: orderType === 'delivery', errorMessage: 'This field is required' },
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <AvField
                          role="presentation"
                          onChange={handleAddressChange}
                          onBlur={() => validateAddress(model.addressInfo)}
                          value={model.addressInfo?.zipCode}
                          label="Zip Code"
                          name="zipCode"
                          autoComplete="off"
                          validate={{
                            required: { value: orderType === 'delivery', errorMessage: 'This field is required' },
                          }}
                        />
                      </Col>

                      <Col md="12">
                        <AvField
                          label="Delivery Instructions"
                          onChange={(e) => setModel({ ...model, deliveryInstructions: e.target.value })}
                          role="presentation"
                          name="deliveryInstructions"
                          rows="3"
                          type="textarea"
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>

              {/* Pickup and Curbside Pickup */}
              {disabledPayments ? (
                <> </>
              ) : (
                <div className="form-section pickup-section">
                  <Nav tabs>
                    {orderType === 'delivery' ? (
                      <> </>
                    ) : (
                      <>
                        {Boolean(settings.payInStore) && (
                          <NavItem>
                            <NavLink className={isCash ? 'active' : ''} onClick={() => handleChangeMethod(METHOD_CASH)}>
                              Pay in Store
                            </NavLink>
                          </NavItem>
                        )}
                      </>
                    )}

                    {Boolean(settings.creditCard) && (
                      <NavItem>
                        <NavLink className={isCard ? 'active' : ''} onClick={() => handleChangeMethod(METHOD_CARD)}>
                          Credit card
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={paymentMethod} className="pay-tablet">
                    {Boolean(settings.payInStore) && (
                      <TabPane tabId={METHOD_CASH}>Pay for your order in store during pickup</TabPane>
                    )}

                    {Boolean(settings.creditCard) && (
                      <TabPane tabId={METHOD_CARD}>
                        <Row>
                          <Col xs="12" md="6">
                            <FormGroup>
                              <label>Card number*</label>
                              <div className="form-control h-auto">
                                <CardNumberElement
                                  fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat' }]}
                                  options={stripeConf}
                                  required
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col xs="6" md="3">
                            <FormGroup>
                              <label className="text-capitalize">Expiration date*</label>
                              <div className="form-control h-auto">
                                <CardExpiryElement options={stripeConf} required />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col xs="6" md="3">
                            <FormGroup>
                              <label>CVC*</label>
                              <div className="form-control h-auto">
                                <CardCvcElement options={stripeConf} required />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <div className="tips-wrapper">
                          <div className="tips-group">
                            <Label style={{ fontWeight: 'bold' }}>Tip the kitchen.</Label>
                            <div className="delivery-tip-container">
                              <FormGroup tag="fieldset" className="d-flex justify-content-around mb-0">
                                <AvRadioGroup
                                  value={tip}
                                  className="d-flex justify-content-around mb-0 deliveryRadios"
                                  name="radio1"
                                >
                                  <AvRadio
                                    className={`mr-3 tip ${tip === 3 ? 'active' : ''}`}
                                    customInput
                                    value={3}
                                    label={'$3'}
                                    onChange={() => handleChangeTip(3)}
                                  />
                                  <AvRadio
                                    className={`mr-3 tip ${tip === 5 ? 'active' : ''}`}
                                    customInput
                                    value={5}
                                    label={'$5'}
                                    onChange={() => handleChangeTip(5)}
                                  />
                                  <AvRadio
                                    className={`mr-3 tip ${tip === 8 ? 'active' : ''}`}
                                    value={8}
                                    customInput
                                    label={'$8'}
                                    onChange={() => handleChangeTip(8)}
                                  />
                                </AvRadioGroup>
                              </FormGroup>

                              <div className="tips-group custom-check">
                                <Label className="aline-items-center">Custom</Label>
                                <span className="dollar-icon">$</span>
                                <input
                                  className="tip-input pl-4"
                                  // type="text"
                                  name="number"
                                  min="1"
                                  value={customTip}
                                  onChange={({ target: { value } }) => {
                                    if (+value >= 0) {
                                      return handleChangeCustomTip(value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <AvCheckboxGroup name="tipInfo.driver">
                            <AvCheckbox
                              trueValue={2}
                              onChange={handleChangeDrivier}
                              customInput
                              id="topValue"
                              type="checkbox"
                              label="Tip additional $2 to driver (They get 100% of it)"
                            />
                          </AvCheckboxGroup>
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                </div>
              )}

              {/* Special Instructions */}
              <div className="form-section px-3 pb-md-5">
                <AvField
                  label="Special Instructions"
                  name="specialInstructions"
                  rows="3"
                  type="textarea"
                  role="presentation"
                />

                {/*  */}
              </div>

              {/* Order Summery */}
              <div className="footer-section p-3 mx-md-3">
                <h3 className="checkout-form-title">Order Summary</h3>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: '#F4F4F4',
                    maxWidth: '498px',
                    '& input': {
                      width: '356px',
                      height: '38px',
                      border: '1px solid #DDDDDD',
                      backgroundColor: 'white',
                    },
                    '& label': {
                      fontSize: '16px',
                    },
                    '& button': {
                      width: '104px',
                      height: '38px',
                      background: '#FF8A00',
                      color: 'white',
                      marginLeft: '8px',
                      border: 'none',
                    },
                    '& .btn:disabled': {
                      color: 'white',
                    },
                  }}
                >
                  <FormGroup>
                    <Label for="exampleText">Enter coupon code</Label>
                    <Stack direction={'row'}>
                      <Input
                        value={couponCode.value}
                        onChange={(e) => setCouponCode({ ...couponCode, value: e.target.value })}
                        label="Enter coupon code"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required' },
                        }}
                        // onChange={(e) => setModel({ ...model, name: e.target.value })}
                      />
                      <Button
                        onClick={handleValidateCouponCode}
                        type="button"
                        // disabled={!couponCode.value || couponCode.loading}
                      >
                        Apply
                      </Button>
                    </Stack>
                  </FormGroup>
                </Box>
                {couponeAmount && (
                  <Box
                    sx={{
                      mt: 1,
                      p: 2,
                      backgroundColor: '#F4F4F4',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      maxWidth: '498px',
                      mb: 2,
                      justifyContent: 'space-between',
                      '& button': {
                        color: '#969696',
                        textDecoration: 'underline',
                        textDecorationColor: '#969696',
                        // background: 'none',
                        // border: 'none',
                      },
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      <img style={{ marginTop: '5px', marginRight: '16px' }} src={Coupon} alt="coupon" />
                      <Box>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{couponCode?.couponeName}</Typography>
                        <Typography sx={{ fontSize: '14px' }}>{couponCode?.couponCode}</Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Button onClick={hanldeResetCouponCode} color="link">
                        Remove
                      </Button>
                    </Box>
                  </Box>
                )}
                <Divider sx={{ maxWidth: '498px', my: 3 }} />

                <table className="order-summary">
                  <tbody>
                    <tr>
                      <td>Subtotal:</td>
                      <td>{formatPrice(subTotal)}</td>
                    </tr>
                    {!!tipVal && (
                      <tr>
                        <td>Tip:</td>
                        <td>{formatPrice(tipVal)}</td>
                      </tr>
                    )}
                    {isDelivery && (
                      <tr>
                        <td>Delivery fee:</td>
                        <td>{formatPrice(deliveryFee)}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Tax:</td>
                      <td>{formatPrice(taxTotal)}</td>
                    </tr>
                    {couponeAmount ? (
                      <tr>
                        <td>Coupon amount:</td>
                        <td>{`-${formatPrice(couponeAmount)}`}</td>
                      </tr>
                    ) : null}

                    {!!discount && (
                      <tr>
                        <td>Discount:</td>
                        <td>-{formatPrice(discount)}</td>
                      </tr>
                    )}
                    <tr className="total">
                      <td className="font-weight-bold">Total:</td>
                      <td className="font-weight-bold">{formatPrice(grandTotal)}</td>
                    </tr>
                    {!!settings.cashback && (
                      <tr className="text-color">
                        <td>Cashback:</td>
                        <td>
                          {formatPrice((+grandTotal * +settings.cashback) / 100)} ({settings.cashback}%)
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="cart-footer mx-md-3">
            {!isOverMin && (
              <Alert className="w-100" color="error">
                Minimum order is {formatPrice(settings.minOrder)}
              </Alert>
            )}
          </div>

          <div className="cart-footer mx-md-3">
            <Button
              color="success"
              size="lg"
              disabled={
                !model.name ||
                !model.phone ||
                !isOverMin ||
                (loading && !cardError) ||
                (orderType === 'delivery' && (addressLoading || !addressInfo.checked || !addressInfo.valid))
              }
              className={`btn-place  ${loading && !cardError ? 'btn-loading' : ''}`}
            >
              PLACE YOUR ORDER
            </Button>

            {!isOverMin && (
              <ModalError errorMessages={`Minimum order is ${formatPrice(settings.minOrder)}`} settings={settings} />
            )}
            {errorMessages ? <ModalError errorMessages={errorMessages} /> : null}
          </div>
        </AvForm>
      )}
    </>
  );
};

export default CheckoutForm;
