import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import useScriptVibeCheck from 'hooks/useScriptVibeCheck';
const OrderSuccess = () => {
  const { state = {} } = useLocation();
  const history = useHistory();
  useScriptVibeCheck(state);

  useEffect(() => {
    const mainContainer = document.getElementsByClassName('app-main');
    const rootContainer = document.getElementById('root');
    const blackBG = document.getElementById('black-background');
    const tag = document.createElement('div');
    tag.setAttribute('id', 'black-background');
    if (history.location.pathname.includes('success') && !blackBG) {
      if (rootContainer.children.length) {
        mainContainer[0].appendChild(tag);
      }
    }
  }, [history.location.pathname]);

  const { settings, business } = useSelector(({ Menu }) => Menu);

  return (
    <Container>
      <div
        className="order-success-page py-5"
        id={history.location.pathname.includes('success') ? 'order-success-page-background' : ''}
      >
        <h1>Thank you!</h1>
        <h2 className="mb-2">Your order successfully placed!</h2>
        {state.orderNumber && <h3 className="mb-2">#{state.orderNumber}</h3>}
        <h3 className="mb-2">
          For additional help please call{' '}
          <a href={`tel:${business.phone}`} className="text-body">
            {business.phone}
          </a>
        </h3>
        <address className="mb-3">{business.address}</address>
        <Button tag={Link} color="link" to={`/${settings.url}`}>
          Continue Shopping
        </Button>
      </div>
    </Container>
  );
};

export default OrderSuccess;
