import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { formatPrice } from 'utils/utils';
import { CartEdit, CartMinus, CartPlus, CartDelete } from 'components/Icons';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { updateProductModal } from 'redux/actions/ProductModal';
import PropTypes from 'prop-types';
import deleteModal from 'components/DeleteModal';

const CartItems = ({ items, onChange, onRemove, restaurant, showActions }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleEditClick = (product) => {
    dispatch(updateProductModal(product));
    history.push(`/${restaurant}/cart?cid=${product.category}&pid=${product._id}&uuid=${product.uuid}`);
  };

  const getItemTotal = useCallback((options, price) => {
    let copyPrice = 0;
    const optValues = options && Object.values(options).flat();
    const optValueWIthTagType = optValues && optValues.find((item) => item.tags?.length);
    copyPrice = optValueWIthTagType ? optValueWIthTagType.price : price;
    for (const key in options) {
      const opt = options[key];
      // eslint-disable-next-line no-loop-func
      opt.forEach((item) => {
        if (!item.tags?.includes('size') && item.isOnePrice) {
          copyPrice += item?.price;
        }
        if (!item.isOnePrice && item.originalPrice === item.price) {
          const optionsValuesInFlat = Object.values(options).flat();
          const modifierWIthTagName = optionsValuesInFlat?.find((modifierItem) => modifierItem.tags?.includes('size'));
          const overrideObj = item.priceOverride.find((item) => item.context_value === modifierWIthTagName?._id);
          if (modifierWIthTagName && overrideObj) {
            copyPrice += +overrideObj?.price;
          }
        }
        if (item.price !== item.originalPrice && item.price) {
          copyPrice += item.price;
        }
      });
    }
    return copyPrice;
  }, []);

  const handleDelete = async (e, uuid) => {
    e.stopPropagation();
    const confirmed = await deleteModal({
      title: '',
      message: 'Are you sure you want to remove this item ?',
      cancelText: 'No',
      confirmText: 'Yes',
      confirmColor: 'primary',
      cancelColor: '',
    });
    if (confirmed) {
      onRemove(uuid);
    }
  };

  return items.map(({ uuid, options, count, instructions, product }) => {
    if (!product) return <></>;
    return (
      <div className="cart-item" key={uuid}>
        <div className="item-content">
          <div className="d-flex justify-content-between">
            <p className="item-title">{product.name}</p>
            <div>
              <Button aria-label="edit" onClick={() => handleEditClick({ ...product, uuid })} className="ml-2 px-2">
                <CartEdit />
              </Button>
            </div>
          </div>
          <div className="content-muted">
            <small className="text-muted w-75 d-block">
              {options
                ? Object.values(options)
                    .flat()
                    .map((i) => `${i.name}${i.price ? ` (${formatPrice(i.price)})` : ''}`)
                    .join(', ')
                : null}
            </small>
            {!!instructions && (
              <small className="text-muted w-75 d-block">
                <b>Instructions: {instructions}</b>
              </small>
            )}
          </div>
          <div className="actions d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <p className="actions-price mb-0">{formatPrice(getItemTotal(options, product.price) * count)}</p>
            </div>
            {showActions && (
              <div className="d-flex align-items-center">
                <div className="item-action">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      {count === 1 ? (
                        <Button aria-label="trash" className="ml-2 px-2" onClick={(e) => handleDelete(e, uuid)}>
                          <CartDelete />
                        </Button>
                      ) : (
                        <Button aria-label="minus" onClick={() => onChange(uuid, count - 1)}>
                          <CartMinus />
                        </Button>
                      )}
                    </InputGroupAddon>
                    <Input value={count} readOnly min="1" />
                    <InputGroupAddon addonType="append">
                      <Button aria-label="plus_icon" onClick={() => onChange(uuid, count + 1)}>
                        <CartPlus className="plus-icon" />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });
};

CartItems.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  restaurant: PropTypes.elementType.isRequired,
  showActions: PropTypes.bool.isRequired,
};

export default CartItems;
