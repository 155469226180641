import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CardSkeleton = () => {
  return (
      <SkeletonTheme>
        <div className="container">
          <div className="row">
            <div className="col-8 p-3 d-flex flex-column">
              <Skeleton height={20} className="w-50" />
              <Skeleton height={20} className="w-100" />
              <Skeleton height={20} width={60} />
            </div>
            <div className="col-4 p-3 d-flex flex-column">
              <div className="d-flex flex-wrap align-items-center h-100 w-100">
                <div className="d-flex col-10">
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8 p-3 d-flex flex-column">
              <Skeleton height={20} className="w-50" />
              <Skeleton height={20} className="w-100" />
              <Skeleton height={20} width={60} />
            </div>
            <div className="col-4 p-3 d-flex flex-column">
              <div className="d-flex flex-wrap align-items-center h-100 w-100">
                <div className="d-flex col-10">
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8 p-3 d-flex flex-column">
              <Skeleton height={20} className="w-50" />
              <Skeleton height={20} className="w-100" />
              <Skeleton height={20} width={60} />
            </div>
            <div className="col-4 p-3 d-flex flex-column">
              <div className="d-flex flex-wrap align-items-center h-100 w-100">
                <div className="d-flex col-10">
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                  <Skeleton height={25} width={30} className="mx-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
  );
};

export default CardSkeleton;
