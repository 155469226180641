import {UPDATE_FILTERED_PRODUCT, HAS_SEARCH_RESULT} from '../actions/types'
const initialState = {
  baseData:{},
  hasResult: false,
};

const Search = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_FILTERED_PRODUCT:
      return { ...state, baseData : payload };
    case HAS_SEARCH_RESULT:
      return { ...state, hasResult : payload };
    default:
      return state;
  }
};

export default Search;
