import React from 'react';
import { Switch, Route } from 'react-router-dom';

const Login = React.lazy( import('pages/Login'));
const Signup = React.lazy( import('pages/Signup'));
const Confirm = React.lazy( import('pages/Confirm'));
const ResetPassword = React.lazy( import('pages/ResetPassword'));
const ForgotPassword = React.lazy( import('pages/ResetPassword'));
const AuthHeader = React.lazy( import('components/AuthHeader'));

const Auth = () => {
  return (
    <div>
      <AuthHeader />
      <Switch>
        <Route 
          path="/auth/login" 
          component={Login} 
          />
        <Route 
          path="/auth/signup" 
          component={Signup} />
        <Route  
          path="/auth/confirm" 
          component={Confirm} 
          />
        <Route
          path="/auth/reset-password/:userId" 
          component={ResetPassword} 
          />
        <Route 
          path="/auth/forgot-password" 
          component={ForgotPassword} 
          />
      </Switch>
    </div>
  );
};

export default Auth;
