import React, { useEffect, useMemo, memo, useState } from 'react';

import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { MENU_NOT_FOUND } from 'redux/actions/types';

import Header from 'components/Header';
import Footer from 'components/Footer';

import Orders from 'pages/Orders';

import About from 'pages/About';
import Contacts from 'pages/Contact';

import Cart from 'pages/Cart';
// import AddToCart from 'pages/AddToCard';
// import CardModal from 'pages/CardModal'
import OrderSuccess from 'pages/OrderSuccess';
import restaurantNotFound from 'assets/icons/restaurant-not-found.svg';

import { useDispatch, useSelector } from 'react-redux';
import { getMenu } from 'redux/actions/menu';
import Loading from 'components/Loading';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCart, getParentCart } from 'redux/actions/cart';
import { getQueryVariable } from 'utils/utils';
import { useSocket } from 'providers/Socket';
import Logo from 'assets/images/logo.png';

let stripPromise;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;
  const [notFound, setNotFound] = useState(false);
  const { subscribe, unsubscribe, eventEmit } = useSocket();
  const { loading, settings, business } = useSelector(({ Menu }) => Menu);
  const isSuccessPage = history.location.pathname.includes('success');

  useEffect(() => {
    eventEmit('join', { id: localStorage.getItem('session_id') });
  }, [eventEmit]);

  useEffect(() => {
    const parentId = getQueryVariable('parentId') || localStorage.getItem('parentId');
    if (parentId) {
      dispatch(getParentCart(parentId));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleGetCart = () => {
      if (business.id) {
        dispatch(getCart({ business: business.id }));
      }
    };
    handleGetCart();
    subscribe('cart.complete', handleGetCart);
    return () => {
      unsubscribe('cart.complete', handleGetCart);
    };
  }, [business.id, dispatch, subscribe, unsubscribe]);

  useEffect(() => {
    if (restaurant) {
      dispatch(getMenu(restaurant))
        .then(() => dispatch({ type: MENU_NOT_FOUND, payload: false }))
        .catch(() => {
          dispatch({ type: MENU_NOT_FOUND, payload: true });
          setNotFound(true);
        });
    } else {
      dispatch({ type: MENU_NOT_FOUND, payload: true });
      setNotFound(true);
    }
  }, [dispatch, history, restaurant]);

  useEffect(() => {
    if (!history.location.pathname.includes('success')) {
      const blackBG = document.getElementById('black-background');
      if (blackBG) {
        blackBG.remove();
      }
    }
  }, [history.location.pathname]);

  const getStripePromise = useMemo(() => {
    if (!stripPromise && !loading) {
      stripPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount: settings.payment });
    }
    return stripPromise;
  }, [loading, settings.payment]);

  if (loading)
    return (
      <Box sx={{ height: 'calc(100vh - 200px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loading size={200} />
      </Box>
    );
  if (notFound) {
    return (
      <Box className="not-found-main">
        <Box
          component="header"
          sx={{ display: 'flex', justifyContent: 'center', py: 3, borderBottom: '3px solid #f79140' }}
        >
          <Box component="img" src={Logo} alt="Orders.co Logo" />
        </Box>
        <Box className="m-auto text-center">
          <Box component="img" src={restaurantNotFound} alt="Restaurant Not Found" />
          <Box component="h2" className="not-found-restaurant" sx={{ color: '#7C7C7C' }}>
            Restaurant not found
          </Box>
        </Box>
        <Footer />
      </Box>
    );
  }

  return (
    <Elements stripe={getStripePromise}>
      <LoadTheme />
      {isSuccessPage && <Header restaurant={restaurant} />}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
        className={`app-content container ${isSuccessPage ? '' : 'body-background'}`}
      >
        <Box>
          {!isSuccessPage && <Header restaurant={restaurant} />}
          <Switch>
            <Route exact path="/:restaurantId" component={Orders} />
            <Route exact path="/:restaurantId/cart" component={Cart} />
            <Route exact path="/:restaurantId/success" component={OrderSuccess} />
            {/* <Route exact path="/:restaurantId/cart/:categoryId/:productId/:uuid" component={CardModal} />
          <Route exact path="/:restaurantId/cart/:categoryId/:productId" component={CardModal} /> */}
            <Route exact path="/:restaurantId/contacts" component={Contacts} />
            <Route exact path="/:restaurantId/about" component={About} />
          </Switch>
        </Box>
        <Footer />
      </Box>
    </Elements>
  );
}

const LoadTheme = memo(() => {
  const { settings } = useSelector(({ Menu }) => Menu);
  const theme = useMemo(() => {
    return getQueryVariable('theme');
  }, []);

  const blackBG = document.getElementById('black-background');
  const colorContainer = document.getElementById('order-success-page-background');
  if (settings?.theme?.includes('classic')) {
    if (blackBG && colorContainer) {
      blackBG.remove();
      colorContainer.style.color = '#3b4452';
    }
  } else {
    if (blackBG && colorContainer) {
      colorContainer.style.color = '#fff';
    }
  }

  const cssName = useMemo(() => {
    if (theme) return atob(theme);
    return settings.theme;
  }, [settings.theme, theme]);

  if (!cssName) return null;
  return <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/themes/newTheme/${cssName}.css`} />;
});

export default App;
