import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import './styles/index.scss';
import App from './app/App';
// import { ClearCacheProvider } from 'react-clear-cache';

import * as serviceWorker from './serviceWorker';
import { SocketProvider } from 'providers/Socket';

import 'fonts/Roboto-Black.ttf';
import 'fonts/Roboto-BlackItalic.ttf';
import 'fonts/Roboto-Bold.ttf';
import 'fonts/Roboto-BoldItalic.ttf';
import 'fonts/Roboto-Italic.ttf';
import 'fonts/Roboto-Light.ttf';
import 'fonts/Roboto-LightItalic.ttf';
import 'fonts/Roboto-Medium.ttf';
import 'fonts/Roboto-MediumItalic.ttf';
import 'fonts/Roboto-Regular.ttf';
import 'fonts/Roboto-Thin.ttf';
import 'fonts/Roboto-ThinItalic.ttf';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
  // <ClearCacheProvider>
  <Provider store={store}>
    <SocketProvider>
      <Router>
        <App />
      </Router>
    </SocketProvider>
  </Provider>,
  // </ClearCacheProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
