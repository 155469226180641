import React, { useMemo, useState, useEffect, useCallback, createRef, useRef, useLayoutEffect } from 'react';
import { addToCart } from 'redux/actions/cart';
import { formatPrice } from 'utils/utils';
import { IconClose } from 'components/Icons';
import { PropTypes } from 'prop-types';
import {
  InputGroupAddon,
  InputGroup,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Loading from 'components/Loading';
import ModifierItem from 'components/ModifierItem';
import { updateModalVisibility } from 'redux/actions/ProductModal';
import Minus from 'assets/icons/minus.svg';
import Plus from 'assets/icons/plus.svg';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const initialItem = { count: 1, options: {}, instructions: '' };

const ProductModal = ({ modal, closeModal, categoryId, productId }) => {
  const { categories, loading } = useSelector(({ Menu }) => Menu);
  const theme = useTheme();

  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [tagTypeModifier, handleTagTypeModifier] = useState(null);
  const [selectedTagTypeModifier, handleSelectedTagTypeModifier] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [item, setItem] = useState(initialItem);
  const [elRefs, setElRefs] = useState([]);
  const modalRef = useRef();
  const refDescription = useRef();
  const modalBlock = useRef();
  const refName = useRef();
  const imageRef = useRef();
  const modalModifiersRef = useRef();
  const nameRef = useRef();
  const titlesNameRef = useRef();
  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);
  const product = useMemo(() => {
    const catId = categoryId;
    const prId = productId;
    const cat = categories.find((i) => i._id === catId);
    if (!cat) return false;
    let product = cat.products.find((i) => i._id === prId);
    if (!product) {
      categories.forEach((i) => {
        const productFromCat = i.products.find((productItem) => productItem._id === prId);
        if (productFromCat) {
          product = productFromCat;
        }
      });
      if (!product) {
        return false;
      }
    }

    if (product?.isOnePrice === false) {
      const dependantModifiers = product.modifiers.filter((item) => item?.isOnePrice === false);

      const sizeTypeModifier = product.modifiers.find((item) => item.tags?.includes('size'));
      if (!sizeTypeModifier) {
        product = null;
      }
      dependantModifiers.forEach((modifierData, index) => {
        modifierData.list.forEach((listItem) => {
          let dependentObject;
          listItem.priceOverride.forEach((priceOverrideItem) => {
            sizeTypeModifier.list.forEach((item) => {
              if (item._id === priceOverrideItem.context_value) {
                dependentObject = true;
              }
            });
          });
          if (!dependentObject) {
            product.modifiers = product.modifiers.filter((item) => item._id !== modifierData._id);
          }
        });
      });
    } else {
      product.modifiers = product.modifiers.filter(
        (modifierData) => modifierData?.isOnePrice !== false && !modifierData.tags?.includes('size')
      );
    }
    return product;
  }, [categoryId, productId, categories]);

  const productFilteredModifiers = useMemo(() => {
    if (!product) return;
    const filteredModifierWithTags = product.modifiers.length
      ? product.modifiers.find((item) => item.tags?.includes('size'))
      : [];
    const filteredModifierWithoutTags = product.modifiers.filter((item) => !item.tags?.includes('size'));
    if (filteredModifierWithTags && product?.isOnePrice === false) {
      handleTagTypeModifier(filteredModifierWithTags);
    }
    return !product?.isOnePrice && filteredModifierWithTags
      ? [filteredModifierWithTags, ...filteredModifierWithoutTags]
      : filteredModifierWithoutTags;
  }, [product]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(product && productFilteredModifiers?.length)
        .fill()
        .map((_, index) => elRefs[index] || createRef())
    );
  }, [product, productFilteredModifiers]);

  const handleChangeCount = (count) => {
    setItem((prev) => ({ ...prev, count }));
  };

  const handleChangeInstructions = (instructions) => {
    setItem((prev) => ({ ...prev, instructions }));
  };

  const validateModifiers = useCallback(
    (newItem) => {
      if (!product) return;
      if (!productFilteredModifiers?.length) return [[], true];
      const errors = [];
      product.modifiers.forEach(({ min, max, _id }) => {
        const options = newItem.options[_id] || [];
        const maxCount = max || 1000;
        if (options.length < min || options.length > maxCount) {
          errors.push(_id);
        }
      });
      setErrors(errors);
      return [errors, !errors.length];
    },
    [product, productFilteredModifiers]
  );

  const toggleOption = (opt) => {
    const { _id, price, name, priceOverride, tags, isOnePrice } = opt;
    if (tags?.includes('size')) {
      const copyObj = { ...item };
      const values = Object.values(copyObj.options);
      values.forEach((opt) => {
        opt.forEach((optValue) => {
          if (!optValue.isOnePrice) {
            const dependentObject = optValue.priceOverride.find(
              (priceOverrideItem) => priceOverrideItem.context_value === _id
            );
            optValue.price = dependentObject ? dependentObject.price : 0;
          }
        });
      });
      setItem(copyObj);
      if (selectedTagTypeModifier?._id === _id) {
        for (let prop in copyObj.options) {
          copyObj.options[prop] = copyObj.options[prop].filter((optValue) => optValue.isOnePrice);
        }

        handleSelectedTagTypeModifier(null);
      } else {
        handleSelectedTagTypeModifier({ name, price, _id });
      }
    }
    if (!isOnePrice) {
      if (!selectedTagTypeModifier) {
        return;
      }
      opt.price = selectedTagTypeModifier
        ? priceOverride.find((item) => item.context_value === selectedTagTypeModifier?._id)?.price
        : 0;
    }

    const prevOptions = item.options ? item.options[opt.modifierId] || [] : [];

    let newOptions = [];
    if (prevOptions.find(({ _id }) => _id === opt._id)) {
      newOptions = prevOptions.filter(({ _id }) => _id !== opt._id);
    } else {
      newOptions = [...prevOptions, opt];
    }
    if (opt.freeCount) {
      newOptions = newOptions
        .sort((a, b) => b.originalPrice - a.originalPrice)
        .map((option, index) => ({ ...option, price: index < opt.freeCount ? 0 : option.originalPrice }));
    }

    const newItem = {
      ...item,
      options: {
        ...item.options,
        [opt.modifierId]: newOptions,
      },
    };
    if (submitted) {
      validateModifiers(newItem);
    }
    setItem(newItem);
  };

  const total = useMemo(() => {
    if (!product) return 0;
    let optPrice = 0;
    for (const key in item.options) {
      if (item.options.hasOwnProperty(key)) {
        const options = item.options[key];
        optPrice += options.reduce((acc, i) => acc + Number(i.freeCount ? i.price : i.originalPrice) || 0, 0);
      }
    }
    if (tagTypeModifier) {
      return (optPrice * item.count).toFixed(2);
    }
    return ((optPrice + product.price) * item.count).toFixed(2);
  }, [item.count, item.options, product, tagTypeModifier]);

  const executeScroll = (errors) => {
    for (let i = 0; i < productFilteredModifiers.length; i++) {
      if (errors.includes(productFilteredModifiers[i]._id)) {
        // For mobile view
        elRefs[i].current && elRefs[i].current.scrollIntoView({ behavior: 'smooth' });
        break;
      }
    }
  };

  const submitOrder = () => {
    setSubmitted(true);
    const [errors, isValid] = validateModifiers(item);
    if (isValid) {
      const data = {
        ...item,
        uuid: item.uuid || uuidv4(),
        product: product._id,
      };
      dispatch(updateModalVisibility(false));
      dispatch(addToCart(data));
      history.replace(`/${restaurant}?cid=${categoryId}`);
    } else {
      executeScroll(errors);
    }
  };

  const handleCloseModal = () => {
    closeModal(restaurant);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const getLines = (el) => {
    const lineHeight = el.style.lineHeight;
    const factor = 100;
    el.style.lineHeight = factor + 'px';
    const height = el.getBoundingClientRect().height;
    el.style.lineHeight = lineHeight;
    return Math.floor(height / factor);
  };

  const handleReadMore = () => {
    if (refDescription?.current) {
      if (document.getElementById('show-more-button')) {
        if (getLines(refDescription.current) > 2) {
          refDescription.current.classList.add('text-muted');
          document.getElementById('show-more-button').style.display = 'flex';
        } else {
          refDescription.current.classList.remove('text-muted');
          if (getLines(refDescription.current) <= 2 && !showMore) {
            document.getElementById('show-more-button').style.display = 'none';
          }
        }
      }
    }
  };

  useLayoutEffect(() => {
    handleReadMore();
  }, [showMore, closeModal]);

  useEffect(() => {
    if (modalBlock.current) {
      let imageHeight = 0;
      const modalFooterHeight = 75;
      const titleHeight = titlesNameRef?.current?.offsetHeight;
      const dialogHeight = modalRef?.current?._dialog?.offsetHeight;

      if (matchesDownMd) {
        imageHeight = imageRef?.current?.offsetHeight;
      }
      modalBlock.current.style.height = dialogHeight - modalFooterHeight - titleHeight + 'px';
    }
  }, [modalRef, modalBlock.current, closeModal, showMore, refName, productId, categoryId, matchesDownMd, imageRef]);

  return (
    <div className="container">
      <Modal
        ref={modalRef}
        className={`${
          product && product.image?.url ? '' : 'cart--modal-single'
        } cart--modal d-flex align-center  product-modal-container`}
        isOpen={modal}
      >
        <ModalHeader>
          <Button aria-label="close" className="card--close-btn" color="secondary" onClick={handleCloseModal}>
            <IconClose />
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className="card--info-container" ref={modalModifiersRef}>
            {/* <Box ref={imageRef}> */}
            {product && product.image?.url && (
              <div className="item-media">
                <img
                  src={`${product?.image?.url.replace(
                    process.env.REACT_APP_REPLACE,
                    process.env.REACT_APP_REPLACE_WITH
                  )}?tr=w-600,h-600`}
                  alt={product.name}
                />
              </div>
            )}
            {/* </Box> */}
            <div className="card--product-info">
              {loading ? (
                <Loading />
              ) : product ? (
                <div ref={nameRef} className="card--content-wrapper">
                  <div ref={titlesNameRef} className="cart--product-desc" key={product._id}>
                    <h5 className="cart-product-title">{product.name}</h5>
                    <div
                      className="cart-product-desc"
                      style={{
                        display: !showMore ? 'flex' : 'block',
                      }}
                    >
                      <div ref={refDescription}>{product.description}</div>
                      {product.description && (
                        <span
                          id="show-more-button"
                          className={`show-more-style ${!showMore ? 'show-more' : 'show-less'}`}
                          onClick={handleShowMore}
                        >
                          {!showMore ? 'Show more' : 'Show less'}
                        </span>
                      )}
                    </div>
                  </div>

                  <Box
                    ref={modalBlock}
                    sx={{
                      overflowY: 'auto',
                      // height: `${modalHeight}px`,
                    }}
                  >
                    {!!productFilteredModifiers.length && (
                      <div className="cart-body-content">
                        <div className="modifiers-list">
                          {productFilteredModifiers.map((modifier, index) =>
                            modifier.list?.length ? (
                              <div key={modifier._id} data-error={errors.includes(modifier._id)} ref={elRefs[index]}>
                                <ModifierItem
                                  data={modifier}
                                  tagTypeModifier={tagTypeModifier}
                                  selectedTagTypeModifier={selectedTagTypeModifier}
                                  onClick={toggleOption}
                                  values={item && item.options[modifier._id]}
                                  hasError={errors.includes(modifier._id)}
                                  key={modifier._id}
                                  col={12}
                                />
                              </div>
                            ) : (
                              ''
                            )
                          )}
                        </div>
                      </div>
                    )}
                    <FormGroup className="mt-3 spacial">
                      <Label>Special instruction</Label>
                      <Input
                        rows="3"
                        name="instruction"
                        aria-label="instruction"
                        type="textarea"
                        value={item.instructions}
                        onChange={({ target }) => handleChangeInstructions(target.value)}
                      />
                    </FormGroup>
                  </Box>
                </div>
              ) : (
                <h2 className="m-auto text-center text-muted">Product not found!</h2>
              )}
              <div className="cart-foot">
                <div className="card--multiply-btn">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <button
                        aria-label="minus"
                        className="btn btn-icon"
                        onClick={() => handleChangeCount(Math.max(item.count - 1, 1))}
                      >
                        <img src={Minus} alt="logo" className="card-icon" />
                      </button>
                    </InputGroupAddon>
                    <Input value={item.count} readOnly min="1" />
                    <InputGroupAddon addonType="append">
                      <button
                        aria-label="count"
                        className="btn btn-icon"
                        onClick={() => handleChangeCount(item.count + 1)}
                      >
                        <img src={Plus} alt="logo" className="card-icon" />
                      </button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="card--bottom">
                  <div className="cart--modal-footer">
                    <Button className="add--basket-btn" color="primary" size="lg" onClick={submitOrder}>
                      ADD TO CART <span>{selectedTagTypeModifier || !tagTypeModifier ? formatPrice(total) : ''}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

ProductModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default ProductModal;
