//import { UPDATE_FILTERED_PRODUCT, HAS_SEARCH_RESULT } from '../actions/types';

export const updateFilterProduct = (body) => (dispatch) => {
  const arrayOfValues = Object.values(body);
  if (!arrayOfValues.flat(1).length) {
    return dispatch({ type: 'HAS_SEARCH_RESULT', payload: true });
  } else {
    return dispatch({ type: 'HAS_SEARCH_RESULT', payload: false });
  }
};
