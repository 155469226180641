import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Table, Button } from 'reactstrap';
import MoreInfo from 'assets/icons/moreInfo.svg';
import { useSelector } from 'react-redux';
import OrangePhone from 'assets/icons/Icon-phone-orange.svg';
import OrangeMail from 'assets/icons/Icon-mail-orange.svg';
import OrangeMap from 'assets/icons/Icon-map-orange.svg';
import { IconClose } from 'components/Icons';
import { Container, Grid, Typography } from '@mui/material';

export const DAYS = {
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
};

const ModalInfo = (props) => {
  //const { buttonLabel, className } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { business } = useSelector(({ Menu }) => Menu);
  return (
    <>
      <div className="info-link" onClick={toggle}>
        <img src={MoreInfo} alt="information tooltip icon" />
      </div>
      <Modal isOpen={modal} toggle={toggle} className="modal-info">
        <ModalHeader className="more-title">
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{business?.companyName?.toUpperCase()}</Typography>
          <Button aria-label="close" className="card--close-btn" color="secondary" onClick={toggle}>
            <IconClose />
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className="contacts-page">
            <Container disableGutters>
              <Grid container spacing={{ md: 3 }}>
                <Grid item md={6} xs={12} sm={12}>
                  <h4 className="mb-3 contact-title">Working Hours</h4>
                  <div className="contact-info mb-5">
                    <Table borderless size="sm">
                      <tbody>
                        {Object.keys(business).length &&
                          Object.keys(DAYS).map((key) => (
                            <tr key={key} className="list-border">
                              <td className="pl-0 day-list">{DAYS[key]}</td>
                              <td colSpan={2} className="pr-0 text-left text-nowrap hour-list">
                                {business.workingHours[key].opened ? business.workingHours[key].timeApPm : 'Closed'}
                                {/* {business.workingHours[key].opened
                                  ? business.workingHours[key].hours.map((i) => `${i.start} - ${i.end}`).join(', ')
                                  : 'Closed'} */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Grid>
                <Grid item md={6} xs={12} sm={12}>
                  <h4 className="mb-3 contact-title">Contacts</h4>
                  <div className="mb-2">
                    <img src={OrangePhone} alt="phone icon" className="mr-2" />
                    <a href={`tel:${business.phone}`} className="text-body">
                      {business.phone}
                    </a>
                  </div>
                  <div className="mb-2">
                    <img src={OrangeMail} alt="email icon" className="mr-2" />
                    <a href={`mailto:${business.email}`} className="text-body ">
                      {business.email}
                    </a>
                  </div>
                  <div className="mb-2 mt-md-5">
                    <img src={OrangeMap} alt="address icon" className="mr-2" />
                    <span className="text-body font-weight-bold">{business?.addressInfo?.address1}</span>
                  </div>
                  <div className="iframe-wrapper shadow-sm">
                    <iframe
                      title="map"
                      frameBorder="0"
                      src={`https://www.google.com/maps/embed/v1/place?key=${
                        process.env.REACT_APP_MAP_KEY
                      }&q=${encodeURI(business?.addressInfo?.address1)}`}
                      allowFullScreen
                    ></iframe>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default ModalInfo;
