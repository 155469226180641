import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Check from 'assets/images/check.png';
import { useTheme } from '@mui/material/styles';
import { getAddress } from 'helpers';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const enums = {
  pickup: 'Pick-up',
  curbsidePickup: 'Curbside Pick-up',
  delivery: 'Delivery',
};

const Restaurant = ({ data }) => {
  const history = useHistory();
  const { addressInfo } = data;
  const theme = useTheme();

  const { addressPart1, addressPart2 } = getAddress(addressInfo);

  return (
    <Card
      sx={{ display: 'flex', width: '100%', backgroundColor: 'white', mb: 3, p: 2, cursor: 'pointer' }}
      onClick={() => history.push(`/${data?.settings?.url}`)}
    >
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}> */}
      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <Box width={data.avatar?.url ? 'calc(100% - 180px)' : '100%'}>
          <CardContent sx={{ width: '100%', p: '0 !important' }}>
            <Typography className="restaurantTitle" variant="h5">
              {data.companyName}
            </Typography>
            {data.cuisineType ? (
              <Box sx={{ my: 2 }}>
                <Typography sx={{ fontSize: '16px', borderRadius: '4px', color: '#646464', mb: 1 }}>
                  {data.cuisineType}
                </Typography>
              </Box>
            ) : (
              ''
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, width: '100%' }}>
              <img style={{ width: '20px' }} src={Check} alt="location information icon" />

              <Box sx={{ display: 'flex', flexWrap: 'wrap', width: 'calc(100% - 50px)' }}>
                <Typography
                  className="fullAddress"
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  sx={{ ml: 1 }}
                >
                  {`${addressPart1} ${addressPart2}`.trim()}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  borderRadius: '4px',
                  color: '#646464',
                  mb: 1,
                }}
              >
                {`${data.distance} mi`}
                <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />
                {`${+data.defaultPrepTime - 10 < 5 ? 5 : +data.defaultPrepTime - 10} - ${+data.defaultPrepTime + 10
                  } min`}
              </Typography>
              <Typography
                sx={{
                  color: '#646464',
                  fontSize: '16px',
                  borderRadius: '4px',
                }}
              >
                {data.settings.pickup && enums.pickup}
                {data.settings.curbsidePickup && (
                  <>
                    {data.settings.pickup && <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />}
                    {enums.curbsidePickup}
                  </>
                )}
                {data.settings.delivery && (
                  <>
                    {(data.settings.pickup || data.settings.curbsidePickup) && (
                      <FiberManualRecordIcon sx={{ fontSize: '7px', mx: 1 }} />
                    )}

                    {enums.delivery}
                  </>
                )}
              </Typography>
            </Box>
          </CardContent>
        </Box>
        {data.avatar?.url ? (
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              minWidth: '179px',
              width: '179px !important',
              height: '179px',
            }}
          >
            <CardMedia
              component="img"
              sx={{ maxWidth: '100%', maxHeight: '100%' }}
              image={`${data?.avatar?.url}?tr=w-179,h-179,cm-pad_resize,bg-F0F0F0`}
              alt={data.companyName}
            />
          </Box>
        ) : (
          ''
        )}
      </Box>
      {/* </Box> */}
    </Card>
  );
};

export default Restaurant;
