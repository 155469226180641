import { SET_RESTAURANTS, RESTAURANTS_LOADING, RESTAURANTS_ERROR } from '../actions/types';

const initialState = {
  restaurants: [],
  loadingRestaurant: false,
  error: '',
};

const Restaurants = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RESTAURANTS:
      return {
        ...state,
        restaurants: payload,
      };
    case RESTAURANTS_LOADING:
      return {
        ...state,
        loadingRestaurant: payload,
      };
    case RESTAURANTS_ERROR:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default Restaurants;
