import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useCallback } from 'react';
import io from 'socket.io-client';

const defaultValue = {};
const SocketContext = createContext(defaultValue);

let connection;
const connect = () => {
  if (!connection) {
    connection = io.connect(process.env.REACT_APP_SOCKET_BASE_URL, {
      reconnection: true,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket'],
    });
  }
  return connection;
};

const SocketProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const socketRef = useRef(connect());
  const emitters = useRef({});

  useEffect(() => {
    const socket = socketRef.current;

    socket.off('disconnect').on('disconnect', () => {
      setConnected(false);
    });

    socket.off('connect').on('connect', () => {
      setConnected(true);
    });

    socket.off('reconnect').on('reconnect', () => {
      setConnected(true);
      Object.keys(emitters.current).forEach((event) => {
        socket.emit(event, emitters.current[event]);
      });
    });

    socket.on('connect_error', () => { });

    return () => {
      socket.disconnect();
    };
  }, []);

  const subscribe = useCallback((event, cb) => {
    socketRef.current.on(event, cb);
  }, []);

  const unsubscribe = useCallback((event, cb) => {
    socketRef.current.removeListener(event, cb);
  }, []);

  const eventEmit = useCallback((event, data) => {
    emitters.current[event] = data;
    socketRef.current.emit(event, data);
  }, []);

  return (
    <SocketContext.Provider value={{ socket: socketRef.current, connected, subscribe, unsubscribe, eventEmit }}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocket() {
  const context = useContext(SocketContext);
  if (context === defaultValue) {
    throw new Error('useSocket must be used within SocketProvider');
  }
  return context;
}

export { useSocket, SocketProvider };
