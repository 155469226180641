import { cloneDeep } from 'lodash';
import moment from 'moment';
import { MENU_RES, MENU_UPDATE_WORKING_HOURS } from 'redux/actions/types';

export function formatPrice(val = 0) {
  return Number(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

export const convertAsapDate = (startMinDate, setCheckAsap, val, business, menuData, initialWorkingHours, dispatch) => {
  if (moment(startMinDate).get('date') === moment(val).get('date')) {
    setCheckAsap({
      minDate: new Date(startMinDate),
    });
    if (!moment(startMinDate).isBefore(moment(val)) && business) {
      business.workingHours[moment(startMinDate).format('ddd').toLowerCase()].hours[0].start = moment(
        startMinDate
      ).format('HH:mm');
      dispatch({
        type: MENU_UPDATE_WORKING_HOURS,
        payload: {
          ...menuData.business.workingHours,
        },
      });
    }
  } else {
    setCheckAsap({
      minDate: new Date(),
    });
    if (moment(startMinDate).isBefore(moment(val)) && business) {
      business.workingHours[moment(startMinDate).format('ddd').toLowerCase()].hours = cloneDeep(initialWorkingHours)[
        moment(startMinDate).format('ddd').toLowerCase()
      ].hours;
      dispatch({
        type: MENU_UPDATE_WORKING_HOURS,
        payload: {
          ...menuData.business.workingHours,
        },
      });
    }
  }
};
