import { combineReducers } from 'redux';
import Cart from './cart';
import Menu from './Menu';
import User from './User';
import ProductModal from './ProductModal';
import Search from './search';
import Restaurants from './Restaurants';

export default combineReducers({
  Cart,
  User,
  Menu,
  ProductModal,
  Search,
  Restaurants,
});
