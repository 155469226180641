import { isMobile } from 'react-device-detect';

export default {
  style: {
    base: {
      fontSize: !isMobile ? '18px' : '16px',
      color: 'black',
      fonts: [
        {
          src: 'https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap',
          family: 'Roboto',
          style: 'normal',
        },
      ],
      lineHeight: '1.25',
      fontWeight: 200,
      letterSpacing: '0.025em',
      fontFamily: 'Roboto, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
