import instance from 'services/api';
//import { updateCartLoading } from './cart';
import { toast } from 'react-toastify';
import { MODAL_ERROR_MESSENGER } from 'redux/actions/types';

export const createOrder = (data) => (dispatch) => {
  return instance
    .post(`/purchase`, data)
    .then(({ data: { data } }) => {
      // localStorage.removeItem('session_id');
      // localStorage.removeItem('parentId');
      return data;
    })
    .catch((err) => {
      dispatch({ type: MODAL_ERROR_MESSENGER, payload: err.response.data.message });
      return Promise.reject(err.response);
    });
};

export const confirmPayment = (data) => (dispatch) => {
  return instance
    .post(`/purchase/confirm-payment`, data)
    .then(({ data: { data } }) => {
      toast.success('Order successfully created !');
      return data;
    })
    .catch((err) => {
      dispatch({ type: MODAL_ERROR_MESSENGER, payload: 'Something went wrong!' });
      return Promise.reject(err);
    });
};

export const validateCoupone = (data) => (dispatch) => {
  return instance
    .post(`/promotion/coupon-validate`, data)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      dispatch({
        type: MODAL_ERROR_MESSENGER,
        payload: err.response.data.message || 'Something went wrong. Please try again.',
      });
      return Promise.reject(err.response);
    });
};
