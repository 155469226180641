import React, { memo, useMemo } from 'react';
import ProductItem from '../ProductItem';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/styles';
import LabelTooltip from 'components/Tooltip';

const MenuSection = ({ data, query, restaurant }) => {
  const theme = useTheme();
  const filteredItems = useMemo(() => {
    const arryOfQuery = query.toLowerCase().replace(/  +/g, ' ').split(' ');
    if (!query.length) return data.products;
    return data.products.filter(({ name }) => {
      return arryOfQuery.every((str) => {
        return name.toLowerCase().includes(str);
      });
    });
  }, [data.products, query]);

  if (!filteredItems.length && query) return null;
  return (
    <ThemeProvider theme={theme}>
      <Box data-name={data.name} variant={'section'}>
        {data.products.length ? (
          <>
            <Box sx={{ mb: 1 }} className="section-info">
              <Typography component={'h2'} variant="h6" fontSize="22px">{`${data.name} ${
                data.disabled ? '(Not available now)' : ''
              }`}</Typography>
              <Typography component={'div'} variant="theme.body2" color="theme.color.secondary">
                {data.description}
              </Typography>
            </Box>
            <Grid container spacing={{ xs: 0, md: 2 }}>
              {filteredItems.map((item) => (
                <Grid item md={6} xs={12} key={item.id}>
                  <ProductItem data={item} restaurant={restaurant} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}
      </Box>
    </ThemeProvider>
  );
};

export default memo(MenuSection);
