import React, { useState, useRef, useMemo } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Alert } from 'reactstrap';
import { formatPrice } from 'utils/utils';

const CheckoutFormShared = ({ total, onSubmit, parent }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e, values) => {
    setLoading(true);
    const formData = {
      ...values,
      parentId: parent._id,
    };
    onSubmit(formData);
  };

  const isOverMax = useMemo(() => total.subTotal >= parent?.maxAmount, [parent?.maxAmount, total.subTotal]);

  return (
    <AvForm ref={formRef} autoComplete="off" onValidSubmit={handleSubmit}>
      <div className="cart-body-content mx-0">
        <div className="checkout-form">
          <h6 className="checkout-form-title">Complete</h6>
          <div className="pb-3">
            <AvField
              label="Name*"
              name="name"
              validate={{
                required: { value: true, errorMessage: 'This field is required' },
              }}
            />
          </div>
          <div className="form-section">
            <AvField label="Special Instructions:" name="specialInstructions" rows="3" type="textarea" />
          </div>
          <div className="py-3">
            <h3 className="checkout-form-title">Order Summary</h3>
            <table className="order-summary">
              <tbody>
                <tr className="total">
                  <td>Total:</td>
                  <td>{formatPrice(total?.subTotal)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="cart-footer">
        <Button color="success" size="lg" disabled={loading || isOverMax} className={loading ? 'btn-loading' : ''}>
          Complete
        </Button>
        {isOverMax && <Alert color="danger">Maximum amount is {formatPrice(parent.maxAmount)}</Alert>}
      </div>
    </AvForm>
  );
};

export default CheckoutFormShared;
