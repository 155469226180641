import React from 'react';
import Loader from 'assets/images/loader.gif';
const Loading = ({ size = 200 }) => {
  return (
    <div className="text-center py-5">
      <img style={{ width: `${size}px` }} src={Loader} alt="loading progress" />
      {/* <LoadingSpinner height={size} /> */}
    </div>
  );
};
export default Loading;
