import { SET_RESTAURANTS, RESTAURANTS_LOADING, RESTAURANTS_ERROR } from './types';
import instance from 'services/api';

export const getRestaurants = (param) => (dispatch) => {
  dispatch({ type: RESTAURANTS_LOADING, payload: true });
  return instance
    .post(`/business/marketplace`, param)
    .then(({ data }) => {
      dispatch({ type: RESTAURANTS_ERROR, payload: '' });
      dispatch(setRestaurants(data.data));
      dispatch({ type: RESTAURANTS_LOADING, payload: false });
      if (!data.data.length) {
        dispatch({ type: RESTAURANTS_ERROR, payload: 'No restaurant is found' });
      }
      return data.data;
    })
    .catch((err) => {
      dispatch({ type: RESTAURANTS_LOADING, payload: false });
      dispatch({ type: RESTAURANTS_ERROR, payload: 'No restaurant is found' });
    })
    .finally(() => {
      dispatch({ type: RESTAURANTS_LOADING, payload: false });
    });
};

export const setRestaurants = (data) => (dispatch) => {
  dispatch({ type: SET_RESTAURANTS, payload: data });
};

export const setRestaurantsLoading = (payload) => (dispatch) => {
  dispatch({ type: RESTAURANTS_LOADING, payload });
};
