import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { IconCalendar } from 'components/Icons';
import { AvInput, AvGroup } from 'availity-reactstrap-validation';
import {
  max,
  getDay,
  format,
  addDays,
  addWeeks,
  setHours,
  isSameDay,
  isBefore,
  addMonths,
  addMinutes,
  setMinutes,
  subMinutes,
  isWithinInterval,
} from 'date-fns';
import moment from 'moment';

import weekDays from 'constants/weekDays';
import { useDispatch, useSelector } from 'react-redux';
import { convertAsapDate } from 'utils/utils';

const DateInputField = ({
  required = false,
  name,
  value,
  openingHours,
  specialDates,
  timeZoneId,
  defaultPrepTime,
  calendarError,
  handleCalendarError,
  pickupTimeData,
  setModel,
  modelData,
  startMinDate,
}) => {
  const [excludedDays, setExcludedDays] = useState([]);
  const [includeTimes, setIncludeTimes] = useState([]);
  const menuData = useSelector(({ Menu }) => Menu);
  const { business } = menuData;
  const initialWorkingHours = business?.initialWorkingHours || [];
  const [date, setDate] = useState();
  const { disableAsap, qty } = pickupTimeData;
  const endDate = moment().minutes(Number(moment().minutes()) + Number(qty));
  const asapDate = moment(endDate).tz(timeZoneId).format('YYYY-MM-DD hh:mm');
  const dispatch = useDispatch();

  const [checkAsap, setCheckAsap] = useState({
    minDate: startMinDate ? new Date(startMinDate) : new Date(),
  });

  useEffect(() => {
    const checkWicked = excludedDays.filter((item) => {
      return moment(item).day() !== 6 || moment(item).day() !== 7;
    });

    const holiday = excludedDays.some((item) => moment(item).get('d') === moment().get('d'));

    if (disableAsap) {
      return setCheckAsap({
        minDate: holiday
          ? new Date(moment(startMinDate).add(1, 'day'))
          : startMinDate
          ? new Date(startMinDate)
          : new Date(),
      });
    } else {
      setCheckAsap({
        minDate: holiday ? new Date(moment().add(1, 'day')) : new Date(),
      });
    }
  }, [pickupTimeData, date, excludedDays, disableAsap, openingHours, startMinDate]);

  const getTimes = useCallback(
    (dateFrom) => {
      if (!dateFrom) {
        setIncludeTimes([]);
        return [];
      }
      // const d = addMinutes(dateFrom, defaultPrepTime);
      const d = dateFrom;
      const times = [];
      const now = new Date(moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm'));
      // const now = addMinutes(new Date(moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm')), defaultPrepTime);

      const isToday = isSameDay(d, now);
      const selectedDay = format(d, 'iii').toLowerCase();
      const specialDay = specialDates.find((item) => isSameDay(new Date(item.date), new Date(d)));
      const { hours, opened } = specialDay || openingHours[selectedDay];
      if (!opened) {
        return times;
      }
      let sortedHours = hours.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
      sortedHours.forEach(({ start, end }) => {
        const s = start.split(':');
        const e = end.split(':');
        const startD = addMinutes(subMinutes(setHours(setMinutes(d, +s[1]), +s[0]), 1), 0); // defaultPrepTime;
        const endD = addMinutes(setHours(setMinutes(d, +e[1]), +e[0]), 1);
        for (let h = 0; h <= 23; h++) {
          for (let m = 0; m <= 3; m++) {
            const curr = setHours(setMinutes(d, m * 15), h);
            const curStart = isToday ? max([now, startD]) : startD;

            if (isBefore(curStart, endD) && isWithinInterval(curr, { start: curStart, end: endD })) {
              times.push(curr);
            }
          }
        }
      });
      setIncludeTimes(times);
      return times;
    },
    [timeZoneId, specialDates, openingHours]
  );

  const handleChange = (val, event) => {
    const eventType = event?.type;
    const timeList = document.getElementsByClassName('react-datepicker__time-list')[0];
    const times = getTimes(val || '');
    convertAsapDate(startMinDate, setCheckAsap, val, business, menuData, initialWorkingHours, dispatch);
    if (!times.length) {
      setDate('');
      return handleCalendarError('This field is invalid');
    }
    if (eventType === 'click') {
      timeList.scrollTop = 0;
      if (!val) return setDate(val);
      const minutes = moment(times[0]).format('mm');
      const hours = moment(times[0]).format('HH');
      const withCorrectTime = setHours(setMinutes(val, minutes), hours);
      setModel({ ...modelData, beReadyTime: withCorrectTime });
      return setDate(withCorrectTime);
    } else {
      setModel({ ...modelData, beReadyTime: val });
      return setDate(val || '');
    }
  };

  const onBlur = useCallback(() => {
    setModel({ ...modelData, beReadyTime: date });
    if (!date) {
      return handleCalendarError('This field is required');
    }
    const times = getTimes(date || '');
    if (!times.length) {
      return handleCalendarError('This field is invalid');
    }
    const selectedDay = format(date, 'iii').toLowerCase();
    const specialDay = specialDates.find((item) => isSameDay(new Date(item.date), new Date(date)));
    const { hours } = specialDay || openingHours[selectedDay];
    let sortedHours = hours.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));
    mainLoop: for (let a = 0; a < sortedHours.length; a++) {
      const { start, end } = sortedHours[a];

      const s = start.split(':');
      const e = end.split(':');
      const startD = addMinutes(subMinutes(setHours(setMinutes(date, +s[1]), +s[0]), 1), 0); //defaultPrepTime;
      const endD = addMinutes(setHours(setMinutes(date, +e[1]), +e[0]), 1);
      const now = new Date(moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm'));
      const isToday = isSameDay(date, now);
      for (let h = 0; h <= 23; h++) {
        for (let m = 0; m <= 3; m++) {
          const curStart = isToday ? max([now, startD]) : startD;

          if (!isBefore(curStart, endD) || isBefore(date, curStart)) {
            handleCalendarError('This field is invalid');
            break mainLoop;
          }
          if (isBefore(curStart, endD) && isWithinInterval(date, { start: curStart, end: endD })) {
            handleCalendarError('');
            break mainLoop;
          } else if (a === sortedHours.length - 1) {
            handleCalendarError('This field is invalid');
            break mainLoop;
          }
        }
      }
    }
  }, [date, getTimes, handleCalendarError, modelData, openingHours, setModel, specialDates, timeZoneId]);

  useEffect(() => {
    if (date) {
      handleCalendarError('');
    }
  }, [date, getTimes, handleCalendarError]);

  useEffect(() => {
    const currDayIndex = getDay(new Date());

    const dates = [];
    if (openingHours) {
      for (const day in openingHours) {
        if (openingHours.hasOwnProperty(day)) {
          const { opened } = openingHours[day];
          // let sortedHours = hours.sort((a, b) => parseFloat(a.start) - parseFloat(b.start));

          const dIndex = weekDays[day].index;
          const firstDay = addDays(new Date(moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm')), dIndex - currDayIndex);

          if (!opened) {
            for (let i = 0; i < 5; i++) {
              const todayIsHoliday = specialDates.find((specialDateItem) => {
                return moment(moment(specialDateItem.date).format('M/DD/yyyy')).isSame(
                  moment(addWeeks(firstDay, i)).format('M/DD/yyyy')
                );
              });
              if (!todayIsHoliday?.opened) {
                dates.push(addWeeks(firstDay, i));
              }
            }
          }
        }
      }
      if (specialDates) {
        specialDates.forEach((item) => {
          if (!item.opened) {
            dates.push(new Date(item.date));
          }
        });
      }
      setExcludedDays(dates);
    }
  }, [openingHours, specialDates, timeZoneId]);
  return (
    <AvGroup name={name}>
      <div className="datepicker-container ">
        <DatePicker
          className={calendarError ? 'calendarInvalid' : 'calendarValid'}
          defaultValue={null}
          required={required}
          showTimeSelect
          timeCaption="Time"
          selected={date}
          timeIntervals={15}
          // minDate={new Date()}
          maxDate={addMonths(new Date(), 1)}
          excludeDates={excludedDays}
          includeTimes={includeTimes}
          onChange={handleChange}
          onBlur={onBlur}
          // onFocus={() => !date && handleChange(new Date(moment().tz(timeZoneId).format('YYYY-MM-DD HH:mm')))}
          onSelect={handleChange}
          placeholderText="Choose Date"
          name={name}
          timeFormat={'HH:mm'}
          dateFormat={'MMM dd yyyy HH:mm'}
          customInput={<AvInput disable="true" name={name} />}
          {...checkAsap}
        />
        <IconCalendar />
      </div>
      {calendarError && <div className="calendarLabelError">{calendarError}</div>}
    </AvGroup>
  );
};

export default DateInputField;
