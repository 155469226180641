import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { formatPrice } from 'utils/utils';
import IconCheck from 'assets/icons/Selected.svg';
import IconUncheck from 'assets/icons/Unselected.svg';
import checkedTrue from 'assets/icons/checkedTrue.svg';
import checkedFalse from 'assets/icons/checkedFalse.svg';

const ModifierItem = ({ data, onClick, values = [], col, hasError, selectedTagTypeModifier, tagTypeModifier }) => {
  const [disabled, setDisabled] = useState(false);
  const min = data.tags?.length ? 1 : data.min;
  const max = data.tags?.length ? 1 : data.max;
  useEffect(() => {
    const allOptions = data.list.map((i) => i._id);
    const selectedCount = allOptions.reduce(
      (acc, i) => (values.findIndex(({ _id }) => _id === i) !== -1 ? acc + 1 : acc),
      0
    );
    if (!max) {
      setDisabled(false);
    } else {
      setDisabled(selectedCount >= max);
    }
  }, [data.list, min, max, values]);

  const handleSelect = (option) => {
    const { _id, price, name, priceOverride } = option;
    onClick({
      modifierId: data._id,
      _id,
      price,
      originalPrice: price,
      name,
      freeCount: data.free,
      priceOverride,
      tags: data.tags,
      isOnePrice: data.isOnePrice,
    });
  };

  return (
    <div className="modifier px-3">
      <div className="modifier-desc py-3 mb-3 d-flex justify-content-between">
        <h5 className="modifier-title m-0">
          {data.name}{' '}
          {!!data.min && <small className={values.length >= data.min ? 'text-success' : 'text-danger'}>Required</small>}
        </h5>
        <div className={'modifier-description d-flex align-items-center'}>
          {!!data.min && (
            <span>
              Select at least: <b>{Math.max(data.min - values.length, 0)}</b>
            </span>
          )}
          {!!data.max && (
            <div className="ml-2">
              Select at most:{' '}
              <b>
                {data.max}/{values.length}
              </b>
            </div>
          )}
          {!!data.free && (
            <div className="ml-2">
              Free options: <b>{data.free}</b>
            </div>
          )}
        </div>
      </div>
      <div className="row m-0">
        {data.list.map((option) => {
          if (!data.isOnePrice) {
            option.price = selectedTagTypeModifier
              ? option.priceOverride.find((item) => item.context_value === selectedTagTypeModifier?._id)?.price
              : 0;
          }
          const isActive = values.find(({ _id }) => _id === option._id);
          return (
            <Col md={col} key={option._id} className="px-0">
              <div
                className={`modifier-option ${!!isActive ? 'active' : disabled ? 'disabled' : hasError ? 'error' : ''}`}
                onClick={() => handleSelect(option)}
              >
                <div className="item-name">{option.name}</div>
                <div className="d-flex align-items-center">
                  {!tagTypeModifier || data.tags?.length || selectedTagTypeModifier || data?.isOnePrice !== false ? (
                    <b className="small modifier-price text-muted">
                      {isActive && isActive.price !== option.price && isActive.freeCount ? (
                        <>
                          <del className="text-danger">+{formatPrice(option.price)}</del>+$0.00
                        </>
                      ) : (
                        <>+{formatPrice(option.price)}</>
                      )}
                    </b>
                  ) : null}
                  {isActive ? (
                    <img src={max === 1 ? IconCheck : checkedTrue} className="item-check" alt="check icon" />
                  ) : (
                    <img src={max === 1 ? IconUncheck : checkedFalse} className="item-uncheck" alt="uncheck icon" />
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </div>
    </div>
  );
};

export default ModifierItem;
