export const SET_RESTAURANTS = 'SET_RESTAURANTS';
export const RESTAURANTS_LOADING = 'RESTAURANTS_LOADING';
export const RESTAURANTS_ERROR = 'RESTAURANTS_ERROR';
// Menu Actions
export const MENU_LOADING = 'MENU_LOADING';
export const MENU_RES = 'MENU_RES';
export const MENU_UPDATE_WORKING_HOURS = 'MENU_UPDATE_WORKING_HOURS';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const MENU_ERROR = 'MENU_ERROR';
export const MENU_NOT_FOUND = 'MENU_NOT_FOUND';

// Cart Actions
export const SET_CART = 'SET_CART';

// Address Actions
export const ADDRESS_ERROR = 'ADDRESS_ERROR';
export const ADDRESS_LOADED = 'ADDRESS_LOADED';
export const ADDRESS_LOADING = 'ADDRESS_LOADING';
export const ADDRESS_DELETED = 'ADDRESS_DELETED';

// Address Actions
export const ORDER_ERROR = 'ORDER_ERROR';
export const ORDER_CREATED = 'ORDER_CREATED';
export const ORDER_LOADING = 'ORDER_LOADING';

// Payment Actions
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_LOADED = 'PAYMENT_LOADED';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_CREATED = 'PAYMENT_CREATED';
export const PAYMENT_UPDATED = 'PAYMENT_UPDATED';
export const PAYMENT_DELETED = 'PAYMENT_DELETED';

export const FAVORITES_LOADED = 'FAVORITES_LOADED';
export const FAVORITES_LOADING = 'FAVORITES_LOADING';
export const FAVORITES_ERROR = 'FAVORITES_ERROR';

// Product Modal
export const UPDATE_PRODUCT_CARD = 'UPDATE_PRODUCT_CARD';
export const UPDATE_MODAL_VISIBILITY = 'UPDATE_MODAL_VISIBILITY';
export const MODAL_ERROR_MESSENGER = 'MODAL_ERROR_MESSENGER';

// Search
export const UPDATE_FILTERED_PRODUCT = 'UPDATE_FILTERED_PRODUCT';
export const HAS_SEARCH_RESULT = 'HAS_SEARCH_RESULT';
