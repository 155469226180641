import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Logo from 'assets/images/logoLight.png';
import useScriptVibecheckWidget from 'hooks/useScriptVibecheckWidget';
import { Container } from '@mui/material';

const Footer = () => {
  const history = useHistory();
  const { notFound } = useSelector(({ Menu }) => Menu);

  const { restaurantId } = useParams();
  const restaurant = window.BUSINESS_ID && window.BUSINESS_ID.length > 2 ? window.BUSINESS_ID : restaurantId;
  // useScriptVibecheckWidget(notFound);

  return (
    <div className="app-footer">
      <Container>
        <Box
          className="footer"
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', py: 2 }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/')}
              src={Logo}
              alt="Orders.co Logo"
              className="footerLogo"
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Link style={{ marginBottom: '16px' }} to={`/legal/privacy`}>
              Privacy Policy
            </Link>
            <Link to={`/legal/terms`}>Terms and conditions</Link>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
