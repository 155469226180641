import initialData from '../../services/token';

const initialState = {
  authorized: false,
  loading: false,
  data: null,
  ...initialData(),
};

const User = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'USER_LOADING':
      return { ...state, loading: true };
    case 'USER_LOADED':
      return {
        ...state,
        ...payload,
      };
    case 'USER_UPDATED':
      return { ...state, loading: false, data: { ...state.data, ...payload } };
    case 'USER_ERROR':
      return { ...state, loading: false };
    case 'USER_REMOVE':
      return { ...initialState, authorized: false };
    default:
      return state;
  }
};

export default User;
