import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { BannerSearch, BannerClose } from '../Icons';

let timeout;
const ProductSearch = ({ onChange }) => {
  const [value, setValue] = useState('');

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(value.trim().toLowerCase());
    }, 800);
  };

  const handleDelete = () => {
    setValue('');
    onChange('');
  };

  return (
    <label className="navbar-search">
      <BannerSearch />
      <Input aria-label="Search" name="search" placeholder="Search" value={value} onChange={handleChange} />
      {value ? <BannerClose onClick={handleDelete} /> : null}
    </label>
  );
};

export default ProductSearch;
