import { useEffect } from 'react';

const useScript = (orderInfo) => {
  useEffect(() => {
    sessionStorage.setItem('orderInfo', JSON.stringify(orderInfo));
    // const vibecheck_plugin = document.createElement('script');
    // // <script src=“https://static01.vibecheck.com/widgets/v1/vchk.js?wId=1”></script>
    // vibecheck_plugin.src = 'https://static01.vibecheck.com/widgets/v1/vchk.js?wId=1';

    function addCSS(cssUrl) {
      let newCss = document.createElement('link');
      newCss.setAttribute('rel', 'stylesheet');
      newCss.setAttribute('href', cssUrl);
      document.getElementsByTagName('head')[0].appendChild(newCss);
    }

    function addDIV(divID) {
      let newDiv = document.createElement('div');
      newDiv.setAttribute('id', divID);
      document.getElementsByTagName('body')[0].appendChild(newDiv);
    }

    function addJS(jsUrl) {
      let newJs = document.createElement('script');
      newJs.setAttribute('src', jsUrl);
      document.getElementsByTagName('body')[0].appendChild(newJs);
    }

    function vchk(params) {
      // const {vId, wId} = params;
      // window.setWebsiteId(wId);
      window.vchkParams = params || {};
    }

    (function () {
      addDIV('vibecheckBox');
      addCSS(`https://static01.vibecheck.com/widgets/v1/index.css?v=${new Date().getTime()}`);
      addJS(`https://static01.vibecheck.com/widgets/v1/index.js?v=${new Date().getTime()}`);
    })();
    function vchk(params) {
      // const {vId, wId} = params;
      // window.setWebsiteId(wId);
      window.vchkParams = params || {};
    }

    vchk({ wId: process.env.REACT_APP_WIBE_CHECK_ID });

    // return () => document.body.removeChild(vibecheck_plugin);
  }, [orderInfo]);
};

export default useScript;
