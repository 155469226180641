import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { NavbarBrand, NavItem, Navbar, Nav, Container } from 'reactstrap';
import ShopCard from 'assets/icons/shopOrange.svg';
import { useSelector } from 'react-redux';
import ModalInfo from 'components/ModalInfo';
import OrangeMap from 'assets/icons/Icon-map-orange.svg';
import { getAddress } from 'helpers';
import { Box } from '@mui/material';
const Header = ({ restaurant }) => {
  const { items } = useSelector(({ Cart }) => Cart);
  const { business } = useSelector(({ Menu }) => Menu);
  const { addressPart1, addressPart2 } = getAddress(business.addressInfo);

  return (
    <header className="app-header">
      <Navbar light expand="md" className="shadow-sm">
        <Container className="d-flex flex-nowrap header-container">
          <NavbarBrand to={`/${restaurant}`} tag={Link} className="d-md-flex">
            {business?.avatar?.url ? (
              <>
                <img
                  src={`${business?.avatar?.url}?tr=w-150,h-150`}
                  className="brand-logo"
                  alt={`${business.companyName} logo`}
                />
                <h1 className="text-capitalize text-body font-weight-bold title">
                  {business?.companyName?.toUpperCase()}
                </h1>
                <span className="sub-title">
                  <img src={OrangeMap} alt="address icon" className="mr-2" />
                  <span className="res-address">{`${addressPart1} ${addressPart2}`.trim()}</span>
                </span>
              </>
            ) : (
              <>
                <h1 className="text-capitalize text-body font-weight-bold title">
                  {business?.companyName?.toUpperCase()}
                </h1>
                <span className="sub-title-no-logo">
                  <img src={OrangeMap} alt="address icon" className="mr-2" />
                  <span className="res-address">{`${addressPart1} ${addressPart2}`.trim()}</span>
                </span>
              </>
            )}
          </NavbarBrand>
          <div className=" d-flex flex-column"></div>

          <Nav navbar className="header-cart-nav flex-row">
            <NavItem className="nav-link headerIcons">
              <ModalInfo />
            </NavItem>
            <NavItem>
              <NavLink className="nav-link headerIcons pr-0 d-flex" to={`/${restaurant}/cart`}>
                {/* <div className="d-flex"> */}
                <img src={ShopCard} alt="shopping cart icon" />
                <Box className="cart-count text-center" component={'span'} sx={{ right: items.length > 9 ? 28 : 24 }}>
                  {items.length}
                </Box>
                {/* </div> */}
              </NavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
      <div className="head-border" />
    </header>
  );
};

export default Header;
