import debounce from 'lodash.debounce';
export const withDebounce = (callback = () => {}, daley = 300) => {
  return debounce(callback, daley);
};

export const getAddress = (addressInfo) => {
  let addressPart1 = '';
  let addressPart2 = '';
  if (!addressInfo)
    return {
      addressPart1,
      addressPart2,
    };

  if (addressInfo?.address1 || addressInfo?.address2) {
    addressPart1 = `${addressInfo.address1} ${addressInfo.address2 || ''}`.trim() + ',';
  }

  if (addressInfo?.state) {
    addressPart2 += `${addressInfo.city}, ${addressInfo.state} ${addressInfo.zipCode}`.trim();
  }

  return {
    addressPart1,
    addressPart2,
  };
};
