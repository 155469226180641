import { UPDATE_PRODUCT_CARD, UPDATE_MODAL_VISIBILITY } from 'redux/actions/types';

const initialState = {
  baseData: {},
  modalVisibility: false,
};

const ProductModal = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PRODUCT_CARD:
      return {
        ...state,
        baseData: payload,
      };
    case UPDATE_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: payload,
      };
    default:
      return state;
  }
};

export default ProductModal;
